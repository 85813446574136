import { MouseEvent, ReactElement } from 'react'
import { Pagination } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'

import './pagination.scss'

type Props = {
  firstPage: number
  limitIndex: number
  handleClick: (e : MouseEvent) => void
}
export default function CustomPagination({ firstPage, limitIndex, handleClick }: Props): ReactElement {
  const { pagesCount, activePage } = useSelector((state: RootState) => state.creation)

  const getItems = () => {
    const paginationItems = []
    for (let index = firstPage; index < limitIndex + firstPage; index += 1) {
      if (index <= pagesCount) {
        paginationItems.push(
          <Pagination.Item
            key={index}
            className={`${index === activePage ? 'active' : ''}`}
            onClick={(e : MouseEvent) => handleClick(e)}
          >
            {index}
          </Pagination.Item>,
        )
      }
    }
    return paginationItems
  }

  return (
    <Pagination className="custom-pagination">
      <Pagination.First
        onClick={(e : MouseEvent) => handleClick(e)}
        disabled={activePage === 1}
        className={`${activePage > 1 ? 'active' : ''}`}
      />
      <Pagination.Prev
        onClick={(e : MouseEvent) => handleClick(e)}
        disabled={activePage === 1}
        className={`${activePage > 1 ? 'active' : ''}`}
      />
      {getItems()}
      <Pagination.Next
        onClick={(e : MouseEvent) => handleClick(e)}
        disabled={activePage === pagesCount}
        className={`${activePage < pagesCount ? 'active' : ''}`}
      />
      <Pagination.Last
        onClick={(e : MouseEvent) => handleClick(e)}
        disabled={activePage === pagesCount}
        className={`${activePage < pagesCount ? 'active' : ''}`}
      />
    </Pagination>
  )
}
