import { ReactElement, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { toggleShowPanel } from 'reducers/label'
import PortfolioService from '../PortfolioService'
import { ToolsProps } from './types'

export default function DeleteLabel({ closePopup }: ToolsProps): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { clickedFeatureInfo } = useSelector((state: RootState) => state.map)
  const { libraryLabel } = useSelector((state: RootState) => state.library)
  const [show, setShow] = useState<boolean>(false)

  const handleShow = () => {
    setShow(true)
  }
  const handleClose = () => {
    setShow(false)
    closePopup(undefined)
  }

  const removeLabel = async () => {
    const feature: GeoJSON.Feature = clickedFeatureInfo || libraryLabel
    const queryParams = {
      projectId: feature.properties?.project_id,
      labelId: feature.id ? feature.id : feature.properties?.object_id,
    }
    dispatch(PortfolioService.deleteLabel(queryParams))
    dispatch(toggleShowPanel(false))
    closePopup(undefined)
    handleClose()
  }

  return (
    <>
      <div role="button" tabIndex={0} className="popup-items" onClick={handleShow}>
        {t('common.delete')}
      </div>
      <Modal
        show={show}
        animation
        centered
        onExited={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <h1>
              {t('Portfolio.deleteLabel')}
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-muted font-weight-light">
          {t('Portfolio.deleteNotice')}
        </Modal.Body>
        <Modal.Footer className="mt-3 d-flex justify-content-between">
          <Button variant="outline-secondary" size="sm" onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <Button variant="outline-danger" size="sm" onClick={removeLabel}>
            {t('common.delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
