import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface MapState {
    clickedFeatureInfo?: GeoJSON.Feature
    hoveredFeatureInfo?: GeoJSON.Feature
    manualLayer: boolean
    gaiaLayer: boolean
}

const initialState: MapState = {
  manualLayer: true,
  gaiaLayer: true,
}

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    updateClickedFeatureInfo: (state, action: PayloadAction<GeoJSON.Feature | undefined>) => {
      state.clickedFeatureInfo = action.payload
    },
    updateHoveredFeatureInfo: (state, action: PayloadAction<GeoJSON.Feature | undefined>) => {
      state.hoveredFeatureInfo = action.payload
    },
    toggleManualObjectsLayer: (state, action: PayloadAction<any>) => {
      state.manualLayer = action.payload
    },
    toggleGaiaObjectsLayer: (state, action: PayloadAction<any>) => {
      state.gaiaLayer = action.payload
    },
  },
})

export const {
  updateClickedFeatureInfo,
  updateHoveredFeatureInfo,
  toggleManualObjectsLayer,
  toggleGaiaObjectsLayer,
} = mapSlice.actions

export default mapSlice.reducer
