import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { MdCheckCircle } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'

export default function ImportedRowsNotice(): ReactElement {
  const { t } = useTranslation()
  const { portfolio } = useSelector((state: RootState) => state.import)

  return (
    <div className="imported-rows-container">
      <MdCheckCircle className="check-icon" />
      <div className="imported-rows-text">
        {portfolio.n_rows}
        {t('Portfolio.Import.importedRows')}
      </div>
    </div>
  )
}
