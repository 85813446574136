/* eslint-disable no-underscore-dangle */
import bbox from '@turf/bbox'
import CreationService from 'components/Dashboard/Creation/CreationService'
import { CreationLevels } from 'components/Dashboard/types'
import GeoEditor from 'components/GeoEditor/GeoEditor'
import MAIN_API from 'config/config'
import {
  MutableRefObject,
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { MapRef } from 'react-map-gl'
import { useDispatch, useSelector } from 'react-redux'
import {
  setLabelToUpdate, toggleShowPanel, toggleUpdateMode, updateAreaFields, updateLabelText,
} from 'reducers/label'
import { RootState } from 'Store'
import { setGeoeditorViewport } from 'components/GeoEditor/reducer'
import Map from './Map'
import { ObjectsLayer } from './ObjectsLayers/types'
import { DEFAULT_VIEWPORT } from './utils'

const sourceLayer = ObjectsLayer.tiv

export default function MapContainer(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const mapRef: MutableRefObject<MapRef | undefined> | undefined = useRef()
  const [viewport, setViewport] = useState(DEFAULT_VIEWPORT)
  const { layerBbox, activeProject } = useSelector((state: RootState) => state.creation)
  const { activeBlock } = useSelector((state: RootState) => state.synoptic)
  const { showGeoeditor } = useSelector((state: RootState) => state.object)
  const { libraryLabel } = useSelector((state: RootState) => state.library)
  const {
    createdLabel, updatedLabel, showColorPanel, deletedLabel, labelToUpdate, creationMode,
  } = useSelector((state: RootState) => state.label)

  useEffect(() => {
    if (labelToUpdate && deletedLabel === labelToUpdate.properties?.object_id) {
      dispatch(toggleUpdateMode(false))
      dispatch(toggleShowPanel(false))
      dispatch(updateAreaFields([]))
      dispatch(updateLabelText(''))
      dispatch(setLabelToUpdate(undefined))
    }
  }, [deletedLabel])

  useEffect(() => {
    if ((activeProject && activeProject.status === CreationLevels.Geographic)) {
      const params = {
        sourceLayer,
        projectId: activeProject.id,
      }
      dispatch(CreationService.getLayersBbox(params))
      fetch(`${MAIN_API.proxy}/chartis/v2/layer/${sourceLayer}/mvt/sch/?project_id=${activeProject?.id}`,
        {
          method: 'GET',
          headers: {
            Authorization:
                  `Bearer ${localStorage.getItem('access_token')}`,
          },
        }).then(resp => resp.json())
        .then(json => {
          const mapReference = mapRef.current?.getMap()
          if (mapReference) {
            mapReference.getSource(ObjectsLayer.tiv).tiles = json.tiles
            mapReference.style.sourceCaches[ObjectsLayer.tiv].clearTiles()
            mapReference.style.sourceCaches[ObjectsLayer.tiv].update(mapReference.transform)
            mapReference.triggerRepaint()
            const newViewport = {
              ...viewport,
              zoom: mapReference.transform._zoom,
              latitude: mapReference.transform._center.lat,
              longitude: mapReference.transform._center.lng,
            }
            setViewport(newViewport)
            dispatch(setGeoeditorViewport(newViewport))
          }
        })
    }
  }, [activeProject, showGeoeditor])

  useEffect(() => {
    if ((layerBbox !== null && createdLabel === undefined && updatedLabel === undefined) && !showGeoeditor) {
      const centeredMap = mapRef.current?.getMap().cameraForBounds(bbox(layerBbox.bbox), {
        padding: 20,
      })
      const newViewport = {
        ...viewport,
        zoom: centeredMap.zoom,
        latitude: centeredMap.center.lat,
        longitude: centeredMap.center.lng,
      }
      setViewport(newViewport)
      dispatch(setGeoeditorViewport(newViewport))
    }
    if ((createdLabel && createdLabel.object.properties.project_id === activeProject?.id)
    || (updatedLabel && updatedLabel.object.properties.project_id === activeProject?.id && !showColorPanel
      && updatedLabel.object.properties.displayed === true)) {
      const url = creationMode ? createdLabel.url : updatedLabel.url
      fetch(url,
        {
          method: 'GET',
          headers: {
            Authorization:
                  `Bearer ${localStorage.getItem('access_token')}`,
          },
        }).then(resp => resp.json())
        .then(json => {
          const centeredMap = mapRef.current?.getMap().cameraForBounds(bbox(json.features[0]), {
            padding: 20,
          })
          const newViewport = {
            ...viewport,
            zoom: centeredMap.zoom,
            latitude: centeredMap.center.lat,
            longitude: centeredMap.center.lng,
          }
          setViewport(newViewport)
          dispatch(setGeoeditorViewport(newViewport))
        })
    }
  }, [layerBbox, createdLabel, updatedLabel])

  useEffect(() => {
    if ((libraryLabel && libraryLabel.geometry !== null && libraryLabel.properties.displayed === true)
    && !showGeoeditor) {
      const centeredMap = mapRef.current?.getMap().cameraForBounds(bbox(libraryLabel), {
        padding: 20,
      })
      const newViewport = {
        ...viewport,
        zoom: !libraryLabel ? viewport.zoom : centeredMap.zoom,
        latitude: centeredMap.center.lat,
        longitude: centeredMap.center.lng,
      }
      setViewport(newViewport)
      dispatch(setGeoeditorViewport(newViewport))
    }
  }, [libraryLabel])

  return (
    <div className={`map-wrapper position-relative${activeBlock === t('Synoptic.navBar.addPortfolio')
      ? ' portfolio' : ''}`}
    >
      {(showGeoeditor)
      && <GeoEditor />}

      {(!showGeoeditor)
      && <Map viewport={viewport} mapRef={mapRef} onViewportChange={setViewport} />}
    </div>
  )
}
