import ContentContainer from 'components/common/Container/ContentContainer'
import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import BigLoader from 'components/common/BigLoader/BigLoader'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleExportLoader, updateExportFlag, updateLaunchExport,
} from 'reducers/export'
import { setError } from 'reducers/app'
import { updateActiveBlock } from 'reducers/synoptic'
import { RootState } from 'Store'
import { TASKS_NAMES, TASKS_STATUS } from 'components/Dashboard/Creation/types'
import { getTaskStatus } from 'components/Dashboard/Creation/utils'
import ExportTopBar from './ExportTopBar'
import ExportService from './ExportService'

export default function ExportDocument(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { exportLoader, launchExport, exportedProject } = useSelector((state: RootState) => state.export)

  useEffect(() => {
    if (exportedProject && launchExport
       && getTaskStatus(exportedProject, TASKS_NAMES.Export) === TASKS_STATUS.Success) {
      dispatch(ExportService.getExportedProject(exportedProject.id))
      dispatch(ExportService.getObjectsLegends(exportedProject.id))
      dispatch(ExportService.getPortfolioLegends(exportedProject.id))
      dispatch(ExportService.getCartridge(exportedProject.id))
      dispatch(updateExportFlag(false))
      dispatch(toggleExportLoader(false))
    } if (getTaskStatus(exportedProject, TASKS_NAMES.Export) === TASKS_STATUS.Pending) {
      dispatch(updateLaunchExport(true))
    } else if (getTaskStatus(exportedProject, TASKS_NAMES.Export) === TASKS_STATUS.Failure) {
      dispatch(updateActiveBlock(t('Synoptic.navBar.createFondPlan')))
      dispatch(updateExportFlag(false))
      dispatch(updateLaunchExport(false))
      dispatch(toggleExportLoader(false))
      dispatch(setError(true))
    }
  }, [exportedProject])

  useEffect(() => {
    if (exportedProject && exportLoader) {
      dispatch(ExportService.getExportStatus(exportedProject.id))
    }
  })

  const getNotice = () => (exportLoader ? (
    <div className="d-flex flex-column">
      <div className="spinner"><BigLoader /></div>
      {t('Export.exportLoading')}
    </div>
  ) : '')

  return (
    <div className="container-view">
      <ExportTopBar />
      <ContentContainer
        fillContent={exportLoader}
        title=""
        notice={getNotice()}
        content={<></>}
      />
    </div>
  )
}
