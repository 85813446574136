import ActionButton from 'components/common/Buttons/ActionButton'
import PortfolioService from 'components/Portfolio/PortfolioService'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { reset, setActiveStep } from 'reducers/import'
import { RootState } from 'Store'

export default function GenerateLabelsButton(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { showImportLoader } = useSelector((state: RootState) => state.import)
  const { activeProject } = useSelector((state: RootState) => state.creation)

  const handleClick = () => {
    dispatch(setActiveStep(-1))
    dispatch(reset())
    dispatch(PortfolioService.getLabelsLayer(activeProject.id))
  }

  return (
    <>
      {!showImportLoader && (
        <ActionButton
          text={t('Portfolio.Import.Buttons.continue')}
          onClick={handleClick}
          rightIcon
          buttonStyle="start-btn"
        />
      ) }
    </>
  )
}
