import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import MapContainer from 'components/Map/MapContainer'
import SectionsDetailsPanel from './SectionsDetailsPanel'
import ObjectsListPanel from '../Objects/ObjectsListPanel'
import ObjectCreationPanel from '../Objects/ObjectCreationPanel'

export default function SynopticDisplay(): ReactElement {
  const { t } = useTranslation()
  const { activeBlock } = useSelector((state: RootState) => state.synoptic)
  const { showObjectPanel, showObjectCreationPanel } = useSelector((state: RootState) => state.object)

  const getDisplayedPanel = () => {
    switch (activeBlock) {
      case t('Synoptic.navBar.createFondPlan'):
        return <SectionsDetailsPanel />
      default:
        return <></>
    }
  }
  return (
    <div className="d-flex flex-row">
      {!showObjectPanel && !showObjectCreationPanel && getDisplayedPanel()}
      {showObjectPanel && <ObjectsListPanel />}
      {!showObjectPanel && showObjectCreationPanel && <ObjectCreationPanel />}
      <MapContainer />
    </div>
  )
}
