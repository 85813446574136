import FormInput from 'components/common/Inputs/FormInput'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { updateEmptyFields } from 'reducers/creation'
import { RootState } from 'Store'
import ActionButton from 'components/common/Buttons/ActionButton'
import { InputField } from 'types'
import { SECTION_FIELDS } from './CreationFields'
import CreationService from '../CreationService'

export default function GeographicPerimeter() : ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    updatedSynoptic, responseError, emptyFields, sectionsList, updatedSection,
  } = useSelector((state: RootState) => state.creation)
  const { activeTab } = useSelector((state: RootState) => state.dashboard)
  const [sectionName, setSectionName] = useState('')
  const [lineCode, setLineCode] = useState('')
  const [pkDebut, setPkDebut] = useState('')
  const [pkFin, setPkFin] = useState('')
  const hasError = sectionName === '' || lineCode === '' || pkDebut === '' || pkFin === ''

  useEffect(() => {
    if (responseError === undefined && updatedSection === undefined) {
      setSectionName('')
      setLineCode('')
      setPkDebut('')
      setPkFin('')
    }
    if (updatedSection) {
      setSectionName(updatedSection.name)
      setLineCode(updatedSection.code_ligne)
      setPkDebut(updatedSection.pk_sncf_debut)
      setPkFin(updatedSection.pk_sncf_fin)
    }
  }, [responseError, sectionsList, updatedSection])

  const getInputValue = (fieldLabel: string) => {
    switch (fieldLabel) {
      case t('Inputs.sectionName'):
        return sectionName
      case t('Inputs.lineNumber'):
        return lineCode
      case t('Inputs.pkDebut'):
        return pkDebut
      default:
        return pkFin
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, fieldLabel: string) => {
    switch (fieldLabel) {
      case t('Inputs.sectionName'):
        setSectionName(event.target.value)
        break
      case t('Inputs.lineNumber'):
        if (!Number.isNaN(+event.target.value)) {
          setLineCode(event.target.value)
        }
        break
      case t('Inputs.pkDebut'):
        setPkDebut(event.target.value)
        break
      default:
        setPkFin(event.target.value)
        break
    }
  }

  const addSection = () => {
    if (hasError) {
      dispatch(updateEmptyFields(true))
    } else {
      const declarationParams = {
        projectId: activeTab.tabId ? activeTab.tabId : updatedSynoptic.id,
        newSection: {
          name: sectionName,
          code_ligne: lineCode,
          pk_sncf_debut: pkDebut,
          pk_sncf_fin: pkFin,
        },
      }
      dispatch(CreationService.declareSection(declarationParams))
    }
  }

  return (
    <>
      <Form className="geographic-input flex-wrap mb-5" autoComplete="off">
        {SECTION_FIELDS.map((field: InputField) => (
          <FormInput
            key={field.key}
            field={field}
            onChange={e => handleChange(e, t(`${field.label}`))}
            value={getInputValue(t(`${field.label}`))}
            extraStyle="mr-4"
            responseError={responseError}
          />
        ))}
        <div
          className={`d-flex${(emptyFields && hasError) || responseError
            ? ' align-items-center pt-2' : ' align-items-end'}`}
        >
          <ActionButton
            onClick={addSection}
            leftIcon
            text={t('Dashboard.Buttons.addSection')}
            buttonStyle="btn-add-section"
          />
        </div>
      </Form>
    </>
  )
}
