import { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { Alert, Snackbar } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getTaskStatus } from 'components/Dashboard/Creation/utils'
import { TASKS_NAMES, TASKS_STATUS } from 'components/Dashboard/Creation/types'
import ExportActionsButtons from './Buttons/ExportActionsButtons'
import './export.scss'
import ObjectsLegends from './ObjectsLegends'
import PortfolioLegends from './PortfolioLegend'
import Cartridge from './Cartridge/Cartridge'

export default function GenerateDocument(): ReactElement {
  const { t } = useTranslation()
  const { svgFile, exportAlert, exportedProject } = useSelector((state: RootState) => state.export)
  const [exportStatus, setExportStatus] = useState<any>('')

  useEffect(() => {
    if (exportedProject && exportAlert) {
      setExportStatus(getTaskStatus(exportedProject, TASKS_NAMES.Export))
    }
  }, [exportedProject])

  const warningText = exportAlert ? t('Export.exportLoadingNotice') : t('Export.exportFail')

  return (
    <>
      <Snackbar
        open={exportAlert || exportStatus !== ''}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => setExportStatus('')}
      >
        <Alert variant="filled" severity={exportAlert || exportStatus === TASKS_STATUS.Failure ? 'warning' : 'success'}>
          {exportAlert || exportStatus === TASKS_STATUS.Failure ? warningText : t('Export.exportSuccess')}
        </Alert>
      </Snackbar>
      <ExportActionsButtons />
      <TransformWrapper maxScale={200} centerOnInit panning={{ excluded: ['input', 'textarea'] }}>
        <TransformComponent>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            height: 'calc(100vh - 163px)',
          }}
          >
            <div className="svg-container">
              <img src={`data:image/svg+xml;utf8,${encodeURIComponent(svgFile)}`} alt="" className="my-2" />

            </div>
            <ObjectsLegends />
            <PortfolioLegends />
            <Cartridge />
          </div>
        </TransformComponent>
      </TransformWrapper>
    </>
  )
}
