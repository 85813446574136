import { ReactElement } from 'react'
import { Nav, NavDropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { updateOrderBy } from 'reducers/dashboard'
import { RootState } from 'Store'
import CreationService from './Creation/CreationService'
import { OrderBy, OrderingList } from './types'

export default function CardOrdering(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { orderBy } = useSelector((state: RootState) => state.dashboard)
  const { activePage } = useSelector((state: RootState) => state.creation)

  const getText = (order : string) => (
    <>
      {t(OrderingList.filter(val => val.key === order)[0].value)}
      {' '}
      {(order === OrderBy.AscendingLastModification
        || order === OrderBy.AscendingCreationDate ? <IoIosArrowRoundUp />
        : <IoIosArrowRoundDown />)}
    </>
  )

  const onOrderChange = (order: string) => {
    dispatch(updateOrderBy(order))
    dispatch(CreationService.getProjectsList({ ordering: order, page: activePage }))
  }

  return (
    <div className="order-dropdown">
      {t('Dashboard.sorted')}
      <Nav className="pl-1">
        <NavDropdown
          id="nav-dropdown-dark-example"
          title={getText(orderBy)}
          drop="down"
        >
          {OrderingList.map(val => (
            val.key !== orderBy && (
            <NavDropdown.Item
              as="button"
              key={val.key}
              onClick={() => onOrderChange(val.key)}
            >
              {getText(val.key)}
            </NavDropdown.Item>
            )
          ))}
        </NavDropdown>
      </Nav>
    </div>
  )
}
