import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApi } from 'types'
import { GeographicCoordinates, Project, Section } from '../types'

type GetParams = {
  ordering?: string,
  page?: number
}

const getProjectsList = createAsyncThunk<any, GetParams>(
  'creation/getProjectsList',
  async (params: GetParams) => {
    const projectsList = await get('/module_schematique/projects/', params)
    return projectsList
  },
)

const getSingleProject = createAsyncThunk<Project, number>(
  'creation/getProjectById',
  async (projectId: number) => {
    const project: Project = await get(`/module_schematique/projects/${projectId}/`)
    return project
  },
)

const createProject = createAsyncThunk<Project, Project, ThunkApi>(
  'creation/create',
  async (newProject: Project, thunkApi) => {
    try {
      const response: Project = await post('/module_schematique/projects/', newProject)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

export type UpdateParams = {
  project: Project,
  creationLevel: number
}

const updateProject = createAsyncThunk<UpdateParams, UpdateParams, ThunkApi>(
  'creation/updateById',
  async (params: UpdateParams, thunkApi) => {
    try {
      const response: Project = await patch(`/module_schematique/projects/${params.project.id}/`, params.project)
      const returnedValue: UpdateParams = { project: response, creationLevel: params.creationLevel }
      return returnedValue
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const deleteProject = createAsyncThunk<number, number, ThunkApi>(
  'creation/deleteProject',
  async (projectId: number, thunkApi) => {
    try {
      await deleteRequest(`/module_schematique/projects/${projectId}/`)
      return projectId
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getSectionsList = createAsyncThunk<Array<Section>, number>(
  'creation/getSectionsList',
  async (projectId: number) => {
    const response = await get(`/module_schematique/projects/${projectId}/sections/`)
    return response.results
  },
)

type DeclarationParams = {
  projectId: string,
  newSection: GeographicCoordinates
}

const declareSection = createAsyncThunk<Section, DeclarationParams, ThunkApi>(
  'creation/declareSection',
  async (params: DeclarationParams, thunkApi) => {
    try {
      const response: Section = await post(`/module_schematique/projects/${params.projectId}/sections/`,
        params.newSection)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

export type DeleteParams = {
  projectId: number,
  sectionId: number
}

const deleteSection = createAsyncThunk<DeleteParams, DeleteParams, ThunkApi>(
  'creation/deleteSection',
  async (params: DeleteParams, thunkApi) => {
    try {
      await deleteRequest(`/module_schematique/projects/${params.projectId}/sections/${params.sectionId}/`)
      return params
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const importSections = createAsyncThunk(
  'creation/import',
  async (projectId: number) => {
    await post(`/module_schematique/projects/${projectId}/sections/update/`, {})
  },
)

type LayersParams = {
  sourceLayer: string,
  projectId: number
}

const getLayersBbox = createAsyncThunk<GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>,
 LayersParams>(
   'creation/getLayersBbox',
   async (params: LayersParams) => {
     const res = await
     get(`/chartis/v2/layer/${params.sourceLayer}/bbox/sch/`, { project_id: params.projectId })
     return res
   },
 )

const CreationService = {
  getProjectsList,
  getSingleProject,
  createProject,
  updateProject,
  deleteProject,
  getSectionsList,
  declareSection,
  deleteSection,
  importSections,
  getLayersBbox,
}

export default CreationService
