import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import GenericTopBar from 'components/common/TopBar/GenericTopBar'
import {
  reset, setActiveStep, toggleFerrolocalizationNoticeDisplay, toggleSettingsNoticeDisplay,
  updateResponceError,
  updateSearchValues, updateUploadedPorfolio,
} from 'reducers/import'
import { RootState } from 'Store'
import ContentContainer from 'components/common/Container/ContentContainer'
import CustomStepper from 'components/common/Stepper/CustomStepper'
import BigLoader from 'components/common/BigLoader/BigLoader'
import {
  updateCount, updateFilters, updateLabelsLists, updatePages, updateWorkPhases,
} from 'reducers/library'
import IMPORT_STEPS from './ImportSteps'
import StepsButtons from './StepsContent/StepsButtons'
import { getNotice, getTitle } from './StepsContent/utils'
import StepsContent from './StepsContent/StepsContent'
import SettingsChoice from './StepsContent/SettingsChoice'
import ImportService from './ImportService'

export default function ImportInstructions(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    activeStep, settingsNoticeDisplay, ferrolocalizationNoticeDisplay, showImportLoader, portfolio,
  } = useSelector((state: RootState) => state.import)
  const { activeProject } = useSelector((state: RootState) => state.creation)

  useEffect(() => {
    if (portfolio && portfolio.code_ligne !== ''
     && Object.values(portfolio.filters).filter((v: any) => v.length !== 0).length === 0) {
      dispatch(ImportService.generatePortfolio(activeProject.id))
      dispatch(updateCount())
      dispatch(updatePages())
      dispatch(updateLabelsLists())
      dispatch(updateWorkPhases([]))
      dispatch(updateFilters({}))
    }
  }, [portfolio])

  const onReturn = () => {
    if (activeStep === -1) dispatch(reset())
    else {
      dispatch(setActiveStep(activeStep - 1))
      dispatch(updateSearchValues([]))
      dispatch(updateResponceError(undefined))
      if (activeStep === 0) dispatch(updateUploadedPorfolio(undefined))
      if (activeStep === 1) {
        dispatch(toggleSettingsNoticeDisplay(true))
        dispatch(updateUploadedPorfolio(undefined))
      }
      if (activeStep === 2) dispatch(toggleFerrolocalizationNoticeDisplay(true))
    }
  }

  const getPreviousStep = () => (activeStep === -1 ? t('Portfolio.Import.backToSynoptic')
    : t('Portfolio.Import.backToPreviousStep'))

  const onClose = () => {
    dispatch(reset())
  }

  const contentNotice = showImportLoader ? (
    <div className="d-flex flex-column">
      <div className="spinner"><BigLoader /></div>
      {t(getNotice())}
    </div>
  ) : t(getNotice())

  return (
    <div className="container-view">
      <GenericTopBar
        onStepBack={onReturn}
        getPreviousStep={getPreviousStep}
        onClose={onClose}
        stepper={activeStep !== -1 ? <CustomStepper steps={IMPORT_STEPS} /> : <></>}
      />
      <ContentContainer
        fillContent={settingsNoticeDisplay || ferrolocalizationNoticeDisplay}
        extraStyle={!settingsNoticeDisplay ? 'settings-content-container' : ''}
        title={t(getTitle())}
        notice={contentNotice}
        content={<StepsContent />}
        contentInputStyle="steps-content"
      />
      {!settingsNoticeDisplay && <SettingsChoice />}
      <div className="d-flex justify-content-center">
        <StepsButtons />
      </div>
    </div>
  )
}
