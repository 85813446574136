/* eslint-disable no-case-declarations */
import { ReactElement, useEffect, useState } from 'react'
import FormInput from 'components/common/Inputs/FormInput'
import { Col, Row } from 'react-bootstrap'
import { InputField } from 'types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateAreaFields, updateSearchValue } from 'reducers/label'
import { AREA_FIELDS, PK_FIELDS } from './PanelField'
import { AreaFields, SearchFields, Tracks } from '../types'
import PortfolioService from '../PortfolioService'
import { getSelectedValue, setSearchValues } from './utils'

type Props = {
    index: number
}
export default function AreaInputFields({ index }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    areaFields, responseError, updateMode, linesList, tracksList, labelToUpdate, searchValue,
  } = useSelector((state: RootState) => state.label)
  const { activeProject } = useSelector((state: RootState) => state.creation)

  const [lineCode, setLineCode] = useState('')
  const [trackName, setTrackName] = useState('')
  const [pkDebut, setPkDebut] = useState('')
  const [pkFin, setPkFin] = useState('')

  useEffect(() => {
    if (activeProject && lineCode !== '') {
      dispatch(PortfolioService.getLineTracks({
        projectId: activeProject.id,
        lineCode,
      }))
      const areasToUpdate = (typeof labelToUpdate?.properties?.areas) === 'string'
        ? JSON.parse(labelToUpdate?.properties?.areas) : labelToUpdate?.properties?.areas
      if (areasToUpdate && areasToUpdate[index - 1]?.code_ligne
       !== lineCode && lineCode !== '') {
        setTrackName('')
        dispatch(updateSearchValue([...searchValue.filter((search: SearchFields) => search.index !== index),
          { index, value: '' }]))
      }
    }
  }, [lineCode])

  useEffect(() => {
    if (lineCode !== '' || trackName !== '' || pkDebut !== '' || pkFin !== '') {
      const newArea = {
        code_ligne: lineCode,
        nom_voie: trackName === '' ? null : trackName,
        pk_debut: pkDebut,
        pk_fin: pkFin,
      }
      const newAreaFields = areaFields.map((area: AreaFields) => (area.index !== index
        ? area : { index, area: newArea }))
      dispatch(updateAreaFields(newAreaFields))
    }
  }, [lineCode, trackName, pkDebut, pkFin])

  useEffect(() => {
    if (updateMode) {
      const actionZone = areaFields.filter((area: AreaFields) => area.index === index)[0].area
      setLineCode(actionZone.code_ligne)
      setTrackName(actionZone.nom_voie === null ? '' : actionZone.nom_voie)
      setPkDebut(actionZone.pk_debut)
      setPkFin(actionZone.pk_fin)
    }
  }, [updateMode, labelToUpdate])

  useEffect(() => {
    if (trackName !== '') {
      const initialSearchValues : Array<SearchFields> = []
      if (searchValue.filter((search: SearchFields) => search.index === index).length === 0) {
        areaFields.forEach((area: AreaFields) => {
          if (area.area.nom_voie !== null) {
            initialSearchValues.push({
              index: area.index,
              value: area.area.nom_voie,
            })
          }
        })
      }
      if (initialSearchValues.length !== 0) dispatch(updateSearchValue(initialSearchValues))
    }
  }, [trackName])

  const handleChange = (value: string, fieldLabel: string) => {
    switch (fieldLabel) {
      case t('Inputs.lineNumber'):
        setLineCode(value)
        break
      case t('Inputs.trackName'):
        setTrackName(value)
        break
      case t('Inputs.pkDebut'):
        setPkDebut(value)
        break
      default:
        setPkFin(value)
        break
    }
  }

  const getInputValue = (fieldLabel: string) => {
    switch (fieldLabel) {
      case t('Inputs.lineNumber'):
        return lineCode
      case t('Inputs.trackName'):
        return trackName
      case t('Inputs.pkDebut'):
        return pkDebut
      default:
        return pkFin
    }
  }

  return (
    <>
      <Row className="mr-0 pl-3 pr-2">
        {AREA_FIELDS.map((field: InputField) => (
          <Col key={field.key}>
            <FormInput
              field={field.label === 'Inputs.lineNumber' ? { ...field, values: linesList }
                : {
                  ...field,
                  values: tracksList.filter((track: Tracks) => track.line === lineCode)[0]?.value,
                  disabled: lineCode === '',
                }}
              onChange={e => {
                handleChange(field.label === 'Inputs.lineNumber' ? e.target?.value : e, t(`${field.label}`))
              }}
              value={getInputValue(t(`${field.label}`))}
              extraStyle="mr-2 mb-3"
              responseError={responseError}
              objectField="areas"
              index={index}
              setSearchValues={(v: string) => {
                setSearchValues(v, index)
              }}
              getSelectedValue={() => getSelectedValue(trackName, index)}
            />
          </Col>
        ))}
      </Row>
      <Row className="mr-0 pl-3 pr-2">
        {PK_FIELDS.map((field: InputField) => (
          <Col key={field.key}>
            <FormInput
              field={field}
              onChange={e => handleChange(e.target.value, t(`${field.label}`))}
              value={getInputValue(t(`${field.label}`))}
              extraStyle="mr-2 mb-2"
              responseError={responseError}
              objectField="areas"
              index={index}
            />
          </Col>
        ))}
      </Row>
      <div className="divider" />
    </>
  )
}
