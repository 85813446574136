import { ReactElement } from 'react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FiTrash } from 'react-icons/fi'
import { MdEdit } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { resetResponseError, setUpdatedSection, updateEmptyFields } from 'reducers/creation'
import { RootState } from 'Store'
import { Section, Task } from '../../types'
import CreationService from '../CreationService'
import { TASKS_NAMES } from '../types'
import ValidationNotice from './ValidationNotice'

export default function SectionsList(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    sectionsList, updatedSynoptic, validationNotice, activeProject,
  } = useSelector((state: RootState) => state.creation)
  const { activeTab } = useSelector((state: RootState) => state.dashboard)

  const onSectionDelete = (sectionId: number) => {
    const projectId = activeTab.tabId ? activeTab.tabId : updatedSynoptic.id
    const params = {
      projectId,
      sectionId,
    }
    dispatch(CreationService.deleteSection(params))
  }

  const onSectionUpdate = (section: Section) => {
    dispatch(setUpdatedSection(section))
    onSectionDelete(section.id)
    dispatch(resetResponseError(undefined))
    dispatch(updateEmptyFields(false))
  }

  const getStatus = (section: Section) => activeProject?.sections.filter((sec: Section) => sec.id
  === section.id)[0]?.tasks.filter((task: Task) => task.name === TASKS_NAMES.Validate)[0]?.result

  return (
    sectionsList.length !== 0 ? (
      <div className="table-wrapper">
        <Table hover borderless className="table-synoptic">
          <thead>
            <tr>
              <th>{t('Inputs.sectionName')}</th>
              <th>{t('Inputs.lineNumber')}</th>
              <th>{t('Inputs.pkDebut')}</th>
              <th>{t('Inputs.pkFin')}</th>
            </tr>
          </thead>
          <tbody>
            {sectionsList && sectionsList.map((section: Section) => (
              <tr key={section.id}>
                <td>{section.name}</td>
                <td>{section.code_ligne}</td>
                <td>{section.pk_sncf_debut}</td>
                <td>{section.pk_sncf_fin}</td>
                <td>
                  <div className="mr-4">
                    <MdEdit className="action-cursor-pointer" onClick={() => onSectionUpdate(section)} />
                  </div>
                  <div className="mr-4">
                    <FiTrash className="action-cursor-pointer" onClick={() => onSectionDelete(section.id)} />
                  </div>
                  {validationNotice && <ValidationNotice status={getStatus(section)} />}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
      : <></>
  )
}
