import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState } from 'Store'
import { CreationLevels, Project } from 'components/Dashboard/types'
import { updateCreationLevel, updateSectionsList } from 'reducers/creation'
import CreationService from '../CreationService'

export default function TemporalPerimeterButton(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { updatedSynoptic, projectsList } = useSelector((state: RootState) => state.creation)
  const { activeTab } = useSelector((state: RootState) => state.dashboard)

  const isDisable = updatedSynoptic && (updatedSynoptic.time_range === undefined
    || (updatedSynoptic.time_range && (updatedSynoptic.time_range.start_year === null
     || updatedSynoptic.time_range.end_year === null)))

  const handleClick = () => {
    // dispatch(CreationService.getSectionsList(activeTab.tabId ? activeTab.tabId : updatedSynoptic.id))
    dispatch(updateSectionsList(activeTab.tabId ? projectsList.filter((project: Project) => project.id
     === activeTab.tabId)[0].sections
      : projectsList.filter((project: Project) => project.id === updatedSynoptic.id)[0].sections))
    if (projectsList.filter((project: Project) => project.id === updatedSynoptic.id
          && project.time_range?.start_year === updatedSynoptic.time_range.start_year
           && project.time_range?.end_year === updatedSynoptic.time_range.end_year).length === 0) {
      dispatch(CreationService.updateProject({ project: updatedSynoptic, creationLevel: CreationLevels.Temporal }))
    } else {
      dispatch(updateCreationLevel(CreationLevels.Temporal))
    }
  }

  return (
    <ActionButton
      onClick={handleClick}
      rightIcon
      text={t('Dashboard.Buttons.geographicPerimetre')}
      buttonStyle="btn-add-synoptic"
      disabled={isDisable}
    />
  )
}
