import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { useTranslation } from 'react-i18next'
import {
  setUpdatedSynoptic, updateCreationLevel, updateCreationMode, updateSectionsList,
} from 'reducers/creation'
import { updateActiveTab, updateTabsList } from 'reducers/dashboard'
import ActionButton from 'components/common/Buttons/ActionButton'
import { updateActiveBlock } from 'reducers/synoptic'
import CreationService from './Creation/CreationService'
import DashboardCard from './DashboardCard'
import DashboardList from './DashboardList'
import { ActiveTab, CreationLevels, Project } from './types'
import DashboardPagination from './DashboardPagination'

export default function Dashboard(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { cardDisplayMode, tabsList, orderBy } = useSelector((state: RootState) => state.dashboard)
  const { projectsList, activePage, deletedProject } = useSelector((state: RootState) => state.creation)

  useEffect(() => {
    dispatch(CreationService.getProjectsList({ ordering: orderBy, page: activePage }))
  }, [deletedProject])

  const onProjectAdd = () => {
    dispatch(updateCreationMode(true))
    dispatch(setUpdatedSynoptic(undefined))
  }

  const onSynopticOpen = (name: string | undefined, id: number | undefined) => {
    const newActiveTab: ActiveTab = {
      tabName: name,
      tabId: id,
    }

    dispatch(updateActiveTab(newActiveTab))
    if (tabsList && tabsList.filter((tab: ActiveTab) => tab.tabId === newActiveTab.tabId).length === 0) {
      const newTabsList: Array<ActiveTab> = [newActiveTab]
      dispatch(updateTabsList(tabsList.concat(newTabsList)))
    }

    const activeProject = projectsList.filter((project: Project) => project.id === newActiveTab.tabId)[0]

    if (activeProject) {
      dispatch(setUpdatedSynoptic(activeProject))
      dispatch(updateCreationLevel(activeProject.status))
      if (activeProject.status === CreationLevels.Temporal) dispatch(updateSectionsList(activeProject.sections))
      if (activeProject.status === CreationLevels.Geographic) {
        dispatch(CreationService.getSingleProject(activeProject.id))
        dispatch(updateCreationMode(false))
        dispatch(updateActiveBlock(t('Synoptic.navBar.createFondPlan')))
      } else {
        dispatch(updateCreationMode(true))
      }
    }
  }

  return (
    <div className="m-4">
      {!cardDisplayMode ? <DashboardList onSynopticOpen={onSynopticOpen} />
        : <DashboardCard onSynopticOpen={onSynopticOpen} /> }
      {projectsList.length !== 0 && <DashboardPagination /> }
      <div className="d-flex justify-content-center">
        <ActionButton
          onClick={onProjectAdd}
          leftIcon
          leftIconStyle="btn-add-synoptic-icon"
          text={t('Dashboard.addSynoptic')}
          buttonStyle="btn-add-synoptic"
        />
      </div>
    </div>
  )
}
