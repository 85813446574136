import {
  ReactElement, useEffect,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Accordion } from 'react-bootstrap'

import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'
import { toggleShowObjectCreationPanel, toggleShowObjectPanel, setNewObjectParams } from 'reducers/object'
import { resetEndOfAction, resetcreateObjectError } from 'reducers/app'

import signalA from 'assets/icons/signalA.svg'
import signalC from 'assets/icons/signalC.svg'
import signalCV from 'assets/icons/signalCV.svg'
import signalD from 'assets/icons/signalD.svg'
import signalGA from 'assets/icons/signalGA.svg'
import signalS from 'assets/icons/signalS.svg'
import iconAdv from 'assets/icons/iconAdv.svg'
import iconRK from 'assets/icons/iconRK.svg'
import iconBV from 'assets/icons/bv.svg'
/* import iconVoieUnique from 'assets/icons/iconVoieUnique.svg'
import iconVoieBanalisee from 'assets/icons/iconVoieBanalisee.svg'
import iconIPCS from 'assets/icons/iconIPCS.svg' */
// import iconTE from 'assets/icons/iconTE.svg'

import ObjectService from './ObjectService'
import './objetcs.scss'

export default function ObjectsListPanel(): ReactElement {
  const dispatch = useDispatch()
  const { activeProject } = useSelector((state: RootState) => state.creation)
  const objectsInfraList = [
    {
      category: 'Infrastructure',
      objects: [
        {
          name: 'Appareil de voie',
          type: null,
          layer: 'module_schematique_infra_adv',
          format: 'Point',
          image: iconAdv,
          object: 'INFRA',
        },
        {
          name: 'RK',
          type: null,
          layer: 'module_schematique_infra_rk',
          format: 'Point',
          image: iconRK,
          object: 'INFRA',
        },
        {
          name: 'Bâtiment voyageur',
          type: null,
          layer: 'module_schematique_infra_bv',
          format: 'Point',
          image: iconBV,
          object: 'INFRA',
        },
      ],
    },
    {
      category: 'Signal',
      objects: [
        {
          name: 'Carré',
          type: 'CARRE',
          layer: 'module_schematique_infra_signal',
          format: 'Point',
          image: signalC,
          object: 'SIGNAL',
        },
        {
          name: 'Carré violet',
          type: 'CARRE_VIOLET',
          layer: 'module_schematique_infra_signal',
          format: 'Point',
          image: signalCV,
          object: 'SIGNAL',
        },
        {
          name: 'Disque',
          type: 'DISQUE',
          layer: 'module_schematique_infra_signal',
          format: 'Point',
          image: signalD,
          object: 'SIGNAL',
        },
        {
          name: "Guidon d'arrêt",
          type: 'GUIDON_DARRET',
          layer: 'module_schematique_infra_signal',
          format: 'Point',
          image: signalGA,
          object: 'SIGNAL',
        },
        {
          name: 'Sémaphore',
          type: 'SEMAPHORE',
          layer: 'module_schematique_infra_signal',
          format: 'Point',
          image: signalS,
          object: 'SIGNAL',
        },
        {
          name: 'Avertissement',
          type: 'AVERTISSEMENT',
          layer: 'module_schematique_infra_signal',
          format: 'Point',
          image: signalA,
        },
      ],
    },
    // {
    //   category: 'Traction électrique',
    //   objects: [
    //     {
    //       name: "Type d'électrification",
    //       type: null,
    //       layer: 'module_schematique_infra_te',
    //       format: 'Point',
    //       image: iconTE,
    //       object: 'TE',
    //     },
    //   ],
    // },
    /* ,
    {
      category: "Mode d'exploitation",
      objects: [
        {
          name: 'Voie unique',
          type: 'VOIE_UNIQUE',
          layer: 'module_schematique_infra_pe',
          format: 'LineString',
          image: iconVoieUnique,
          object: 'PE',
        },
        {
          name: 'Voie banalisée',
          type: 'VOIE_BANALISEE',
          layer: 'module_schematique_infra_pe',
          format: 'LineString',
          image: iconVoieBanalisee,
          object: 'PE',
        },
        {
          name: 'IPCS',
          type: 'IPCS',
          layer: 'module_schematique_infra_pe',
          format: 'LineString',
          image: iconIPCS,
          object: 'PE',
        },
      ],
    }, */
  ]

  useEffect(() => {
    dispatch(ObjectService.getObjectsList(activeProject.id))
  }, [activeProject])

  const onDragEnd = (result: any) => {
    dispatch(resetEndOfAction(false))
    dispatch(setNewObjectParams(result))
    dispatch(toggleShowObjectCreationPanel(true))
    dispatch(toggleShowObjectPanel(false))
    dispatch(resetcreateObjectError(false))
  }

  function getStyle(style: any, snapshot: DraggableStateSnapshot) {
    if (!snapshot.isDropAnimating) {
      return style
    }
    return {
      ...style,
      transitionDuration: '0.00001s',
    }
  }

  return (
    <div className="panel">
      <div className="d-flex flex-column justify-content-between">
        <Accordion>
          {objectsInfraList && objectsInfraList.map((object: any) => (
            <Accordion.Item
              key={object.category}
              eventKey={object.category}
            >
              <Accordion.Header>{object.category}</Accordion.Header>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {dropProvided => (
                    <div {...dropProvided.droppableProps} ref={dropProvided.innerRef}>
                      {object.objects.map((obj: any, index: number) => (
                        <Draggable
                          key={obj.type}
                          draggableId={`${obj.type}/${obj.layer}/${obj.name}/${obj.format}/${obj.object}`}
                          index={index}
                        >
                          {(dragProvided: any, snapshot) => (
                            <Accordion.Body
                              className="d-flex align-items-center py-1"
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              {...dragProvided.dragHandleProps}
                              style={getStyle(dragProvided.draggableProps.style, snapshot)}
                              key={obj.name}
                            >
                              <div className="bg-light">
                                <img src={obj.image} width={75} height={75} alt="" />
                              </div>
                              <div className="ml-4">{obj.name}</div>
                            </Accordion.Body>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  )
}
