import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { updateActiveTab } from 'reducers/dashboard'
import {
  setUpdatedSection,
  toggleValidationNotice,
  updateActiveProject, updateCreatedProject, updateCreationLevel, updateCreationMode, updateEmptyFields,
} from 'reducers/creation'
import { RootState } from 'Store'
import GenericTopBar from 'components/common/TopBar/GenericTopBar'
import { CreationLevels } from '../types'

export default function CreationTopBar(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { creationLevel } = useSelector((state: RootState) => state.creation)

  const getPreviousStep = () => {
    switch (creationLevel) {
      case CreationLevels.Name:
        return t('Dashboard.CreationTexts.backToName')
      case CreationLevels.Temporal:
        return t('Dashboard.CreationTexts.backToParams')
      case CreationLevels.Geographic:
        return t('Dashboard.CreationTexts.backToGeo')
      default:
        return t('Dashboard.CreationTexts.backHome')
    }
  }

  const onStepBack = () => {
    switch (creationLevel) {
      case CreationLevels.Name:
        dispatch(updateCreationLevel(undefined))
        dispatch(updateEmptyFields(false))
        break
      case CreationLevels.Temporal:
        dispatch(updateCreationLevel(CreationLevels.Name))
        dispatch(updateEmptyFields(false))
        dispatch(toggleValidationNotice(false))
        dispatch(setUpdatedSection(undefined))
        break
      case CreationLevels.Geographic:
        dispatch(updateCreationLevel(CreationLevels.Temporal))
        dispatch(updateEmptyFields(false))
        break
      default:
        dispatch(updateCreationMode(false))
        dispatch(updateActiveTab({ tabName: 'home', tabId: undefined }))
        dispatch(updateEmptyFields(false))
        break
    }
  }

  const onClose = () => {
    dispatch(updateCreationMode(false))
    dispatch(updateCreationLevel(undefined))
    dispatch(updateCreatedProject(undefined))
    dispatch(updateActiveProject(undefined))
    dispatch(updateActiveTab({ tabName: 'home', tabId: undefined }))
    dispatch(updateEmptyFields(false))
    dispatch(toggleValidationNotice(false))
    dispatch(setUpdatedSection(undefined))
  }

  return (
    <GenericTopBar
      onStepBack={onStepBack}
      getPreviousStep={getPreviousStep}
      onClose={onClose}
    />
  )
}
