import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import './label.scss'
import { RootState } from 'Store'
import { setLabelToUpdate, toggleEnterEditingMode } from 'reducers/label'
import PortfolioService from '../PortfolioService'

export default function ValidateLabelEditingButton(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { labelToUpdate, editionParams } = useSelector((state: RootState) => state.label)

  const handleClick = () => {
    if (editionParams) {
      const params = {
        projectId: labelToUpdate?.properties.project_id,
        labelId: labelToUpdate?.properties.object_id,
        params: editionParams,
      }
      dispatch(PortfolioService.updateLabel(params))
    } else {
      dispatch(toggleEnterEditingMode(false))
      dispatch(setLabelToUpdate(undefined))
    }
  }

  return (
    <ActionButton
      text={t('Portfolio.labelGeoEditorValidation')}
      onClick={handleClick}
      buttonStyle="validate-geoeditor-btn"
    />
  )
}
