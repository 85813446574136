import { ReactElement } from 'react'
import NoticeBadges from 'components/common/Badge/NoticeBadge'
import { useTranslation } from 'react-i18next'
import { IMPORT_STATUS, VALIDATION_NOTICE } from '../types'

type Props = {
    status: string
}

export default function ValidationNotice({ status }: Props): ReactElement {
  const { t } = useTranslation()
  switch (status) {
    case IMPORT_STATUS.EmptyRange:
      return (
        <NoticeBadges notice={t(`${VALIDATION_NOTICE.EmptyRange}`)} />
      )
    case IMPORT_STATUS.LineError:
      return (
        <NoticeBadges notice={t(`${VALIDATION_NOTICE.LineError}`)} />
      )
    case IMPORT_STATUS.ImportError:
      return (
        <NoticeBadges />
      )
    case IMPORT_STATUS.Validated:
      return (
        <NoticeBadges isValide />
      )
    default:
      return <></>
  }
}
