import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { updateImportFlag, updateResponceError } from 'reducers/import'

import '../label.scss'

export default function ImportPortfolio(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleClick = () => {
    dispatch(updateImportFlag(true))
    dispatch(updateResponceError(undefined))
  }

  return (
    <ActionButton
      text={t('Portfolio.Import.importPortfolio')}
      onClick={handleClick}
      buttonStyle="import-portfolio-btn"
    />
  )
}
