import { ReactElement } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateActiveBlock } from 'reducers/synoptic'
import { reset, updateExportFlag } from 'reducers/export'
import PortfolioTabContent from 'components/Portfolio/PortfolioTabContent'
import CreationService from 'components/Dashboard/Creation/CreationService'
import {
  toggleShowPanel, updateResponseError, toggleUpdateMode, updateLabelText, updateSearchValue,
} from 'reducers/label'
import { toggleShowGeoeditor } from 'reducers/object'
import { updateClickedFeatureInfo } from 'reducers/map'
import ExportService from 'components/Export/ExportService'
import GenerateDocument from 'components/Export/GenerateDocument'
import SynopticDisplay from './SynopticDisplay'

export default function SynopticNavBar(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeBlock } = useSelector((state: RootState) => state.synoptic)
  const { activeProject } = useSelector((state: RootState) => state.creation)
  const { updateMode } = useSelector((state: RootState) => state.label)

  const onBlockSelect = (blockName: string) => {
    dispatch(toggleShowGeoeditor(false))
    dispatch(updateActiveBlock(blockName))
    dispatch(updateClickedFeatureInfo(undefined))
    if (blockName === t('Synoptic.navBar.addPortfolio')) {
      dispatch(CreationService.getSingleProject(activeProject?.id))
      dispatch(toggleShowPanel(false))
      dispatch(updateResponseError(undefined))
      dispatch(updateSearchValue([]))
      if (updateMode) {
        dispatch(toggleUpdateMode(false))
        dispatch(updateLabelText(''))
      }
    }
    if (blockName === t('Synoptic.navBar.generateDoc')) {
      dispatch(reset())
      dispatch(updateExportFlag(true))
      dispatch(ExportService.getExportStatus(activeProject.id))
      dispatch(ExportService.exportProject({ projectId: activeProject.id, params: { complete: false } }))
    }
  }

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey={t('Synoptic.navBar.createFondPlan') as string}>
      <Nav variant="tabs" className="synoptic-navigation-tab">
        <Nav.Item className="action-cursor-pointer">
          <Nav.Link
            eventKey={t('Synoptic.navBar.createFondPlan') as string}
            active={activeBlock === t('Synoptic.navBar.createFondPlan')}
            onClick={() => onBlockSelect(t('Synoptic.navBar.createFondPlan'))}
          >
            {t('Synoptic.navBar.createFondPlan')}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="action-cursor-pointer">
          <Nav.Link
            eventKey={t('Synoptic.navBar.addPortfolio') as string}
            active={activeBlock === t('Synoptic.navBar.addPortfolio')}
            onClick={() => onBlockSelect(t('Synoptic.navBar.addPortfolio'))}
          >
            {t('Synoptic.navBar.addPortfolio')}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="action-cursor-pointer">
          <Nav.Link
            eventKey={t('Synoptic.navBar.generateDoc') as string}
            active={activeBlock === t('Synoptic.navBar.generateDoc')}
            onClick={() => onBlockSelect(t('Synoptic.navBar.generateDoc'))}
          >
            {t('Synoptic.navBar.generateDoc')}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        <Tab.Pane
          eventKey={t('Synoptic.navBar.createFondPlan') as string}
          active={activeBlock === t('Synoptic.navBar.createFondPlan')}
          unmountOnExit
        >
          <SynopticDisplay />
        </Tab.Pane>
        <Tab.Pane
          eventKey={t('Synoptic.navBar.addPortfolio') as string}
          active={activeBlock === t('Synoptic.navBar.addPortfolio')}
          unmountOnExit
        >
          <PortfolioTabContent />
        </Tab.Pane>
        <Tab.Pane
          eventKey={t('Synoptic.navBar.generateDoc') as string}
          active={activeBlock === t('Synoptic.navBar.generateDoc')}
        >
          <GenerateDocument />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  )
}
