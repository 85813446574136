import { InputField, InputTypes } from 'types'

const PHASES_FIELD: InputField[] = [
  {
    key: 'phase_travaux',
    label: 'Portfolio.Library.Filters.workPhase',
    hideLabel: true,
    placeholder: 'common.search',
    required: false,
    inputType: InputTypes.Select,
    withSearch: true,
  },
  {
    key: 'date',
    label: 'Portfolio.Library.Filters.date',
    hideLabel: true,
    placeholder: 'common.search',
    required: false,
    inputType: InputTypes.Select,
    withSearch: true,
    multiple: true,
  },
]

export default PHASES_FIELD
