import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useTranslation } from 'react-i18next'

import './label.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { toggleDisplayLibrary } from 'reducers/library'
import PortfolioService from '../PortfolioService'
import { Area, AreaFields } from '../types'

export default function PanelValidationButton(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeProject } = useSelector((state: RootState) => state.creation)
  const {
    areaFields, labelText, updateMode, labelToUpdate,
  } = useSelector((state: RootState) => state.label)

  const getLabelId = () => {
    if (updateMode) {
      if (labelToUpdate.id) return labelToUpdate.id
      return labelToUpdate.properties.object_id
    }
    return null
  }
  const onLabelAction = () => {
    const areasToCreate: Array<Area> = []
    areaFields.forEach((area: AreaFields) => areasToCreate.push(area.area))
    const labelId = getLabelId()
    const params = {
      projectId: activeProject.id,
      labelId,
      params: {
        properties: {
          description: labelText,
          areas: areasToCreate,
        },
      },
    }

    if (updateMode) {
      dispatch(PortfolioService.updateLabel(params))
    } else {
      dispatch(PortfolioService.createLabel(params))
    }
    dispatch(toggleDisplayLibrary(false))
  }

  return (
    <ActionButton
      text={t('Portfolio.validationButton')}
      onClick={onLabelAction}
      buttonStyle="validation-btn"
    />
  )
}
