import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { FiltersCategories, ObjectsLayer } from 'components/Map/ObjectsLayers/types'
import { getFilterCheckStatus, getLayerVisibility } from '../utils'

export default function BvLayer(): ReactElement {
  const { bvLayerData, filtersList } = useSelector((state: RootState) => state.object)

  return (
    <>
      <Source type="geojson" data={bvLayerData} id={ObjectsLayer.bv}>
        <Layer
          id={ObjectsLayer.bv}
          filter={['==', 'auto_added', true]}
          type="symbol"
          maxzoom={15}
          paint={{
            'icon-color': '#FFFFFF',
            'text-color': '#202',
            'text-halo-color': '#fff',
            'text-halo-width': 2,
          }}
          layout={{
            'text-font': ['Roboto Condensed'],
            'text-size': 12,
            'text-transform': 'uppercase',
            'text-allow-overlap': false,
            'text-letter-spacing': 0.05,
            'text-field': ['get', 'description'],
            visibility: getLayerVisibility(filtersList, FiltersCategories.Infra, ObjectsLayer.bv,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
        <Layer
          id={`small-${ObjectsLayer.bv}`}
          filter={['==', 'auto_added', true]}
          type="symbol"
          minzoom={15}
          paint={{
            'icon-color': '#FFFFFF',
            'text-color': '#202',
            'text-halo-color': '#fff',
            'text-halo-width': 2,
          }}
          layout={{
            'text-font': ['Roboto Condensed'],
            'text-size': 12,
            'text-transform': 'uppercase',
            'text-allow-overlap': false,
            'text-letter-spacing': 0.05,
            'text-field': ['get', 'description'],
            'icon-image': 'bv',
            'icon-text-fit': 'both',
            'icon-text-fit-padding': [15, 15, 15, 15],
            visibility: getLayerVisibility(filtersList, FiltersCategories.Infra, ObjectsLayer.bv,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
      </Source>
    </>
  )
}
