/* eslint-disable react/no-array-index-key */
import { ReactElement } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { IoIosClose } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import ExportService from './ExportService'
import { ObjectLegend } from './types'
import { getLegendContainers } from './utils'

const ROWS_LIMIT = 10

export default function ObjectsLegends(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { objectsLegends, colorMap } = useSelector((state: RootState) => state.export)
  const { activeProject } = useSelector((state: RootState) => state.creation)

  const legendContainers: number = objectsLegends.length % ROWS_LIMIT !== 0
    ? Math.floor(objectsLegends.length / ROWS_LIMIT) + 1 : objectsLegends.length / ROWS_LIMIT

  const handleClick = (legend: string) => {
    dispatch(ExportService.updateObjectsLegends({ projectId: activeProject.id, legends: [legend] }))
  }

  return (
    objectsLegends.length !== 0 ? (
      <>
        {getLegendContainers(legendContainers).map((c: number) => (
          <Col key={c} className={`object-legend-container mr-3${colorMap ? '' : ' no-portfolio-legend'}`}>
            {c === 0 && <div className="legend-header">{t('Export.objectsLegend')}</div>}
            <div className="legend-container">
              {objectsLegends.slice(c * ROWS_LIMIT, (c + 1) * ROWS_LIMIT).map((legend: ObjectLegend, index: number) => (
                <Row key={index}>
                  <Col>
                    <img src={`data:image/svg+xml;utf8,${encodeURIComponent(legend.svg)}`} alt="" className="my-2" />
                  </Col>
                  <Col className="object-legend" sm={6}>{legend.legend}</Col>
                  <Col sm={2} className="legend-btn">
                    <IoIosClose className="action-cursor-pointer" onClick={() => handleClick(legend.legend)} />
                  </Col>
                </Row>
              ))}
            </div>
          </Col>
        ))}
      </>
    ) : <></>
  )
}
