import { ReactElement, useEffect, useState } from 'react'
import MapContainer from 'components/Map/MapContainer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { useTranslation } from 'react-i18next'
import { updateApplyFilters } from 'reducers/library'
import PortfolioPanel from './Panel/PortfolioPanel'
import LibraryPanel from './Panel/LibraryPanel'
import PortfolioService from './PortfolioService'
import ImportService from './Import/ImportService'
import LibraryService from './Library/LibraryService'
import { Libraries } from './Library/types'

export default function PortfolioTabContent(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    showPanel, updatedLabel, deletedLabel, createdLabel,
  } = useSelector((state: RootState) => state.label)
  const { activeBlock } = useSelector((state: RootState) => state.synoptic)
  const { activeProject } = useSelector((state: RootState) => state.creation)
  const { importedPortfolio } = useSelector((state: RootState) => state.import)
  const {
    importedLabelsPage, createdLabelsPage, notPositionedLabelsPage, importedLabelsCount, createdLabelsCount,
    notPositionedLabelsCount, importedLabels, createdLabels, notPositionedLabels, displayLibrary, applyFilters,
  } = useSelector((state: RootState) => state.library)
  const [positionedLabelsPagesNumber, setPositionedLabelsPagesNumber] = useState(0)
  const [createdLabelsPagesNumber, setCreatedLabelsPagesNumber] = useState(0)
  const [notPositionedLabelsPagesNumber, setNotPositionedLabelsPagesNumber] = useState(0)

  useEffect(() => {
    if (activeProject && activeBlock === t('Synoptic.navBar.addPortfolio')) {
      dispatch(PortfolioService.getProjectLines(activeProject.id))
    }
    if (activeProject) {
      dispatch(ImportService.getPortfolio(activeProject.id))
    }
  }, [activeProject])

  useEffect(() => {
    if (importedLabelsPage > positionedLabelsPagesNumber && activeProject) {
      if (importedLabelsCount !== importedLabels.length || importedLabels.length === 0) {
        dispatch(LibraryService.getLabels({
          projectId: activeProject.id,
          library: Libraries.Positioned,
          page: importedLabelsPage,
        }))
      }
      setPositionedLabelsPagesNumber(positionedLabelsPagesNumber + 1)
    }
  }, [importedLabelsPage])

  useEffect(() => {
    if ((updatedLabel || deletedLabel) && activeProject) {
      dispatch(LibraryService.getLabels({
        projectId: activeProject.id,
        library: Libraries.Positioned,
        page: importedLabelsPage,
      }))
      setPositionedLabelsPagesNumber(1)
    }
  }, [updatedLabel, deletedLabel])

  useEffect(() => {
    if (applyFilters && activeProject) {
      setTimeout(() => {
        dispatch(LibraryService.getLabels({
          projectId: activeProject.id,
          library: Libraries.Positioned,
          page: importedLabelsPage,
        }))
      }, 1000)
      setPositionedLabelsPagesNumber(1)
      dispatch(updateApplyFilters(false))
    }
  }, [applyFilters, importedPortfolio])

  useEffect(() => {
    if (createdLabelsPage > createdLabelsPagesNumber && activeProject) {
      if (createdLabelsCount !== createdLabels.length || createdLabels.length === 0) {
        dispatch(LibraryService.getLabels({
          projectId: activeProject.id,
          library: Libraries.Manual,
          page: createdLabelsPage,
        }))
      }
      setCreatedLabelsPagesNumber(createdLabelsPagesNumber + 1)
    }
  }, [createdLabelsPage])

  useEffect(() => {
    if ((updatedLabel || deletedLabel || createdLabel) && activeProject) {
      dispatch(LibraryService.getLabels({
        projectId: activeProject.id,
        library: Libraries.Manual,
        page: createdLabelsPage,
      }))
      setCreatedLabelsPagesNumber(1)
    }
  }, [updatedLabel, deletedLabel, createdLabel])

  useEffect(() => {
    if (notPositionedLabelsPage > notPositionedLabelsPagesNumber && activeProject) {
      if (notPositionedLabelsCount !== notPositionedLabels.length || notPositionedLabels.length === 0) {
        dispatch(LibraryService.getLabels({
          projectId: activeProject.id,
          library: Libraries.NotPositioned,
          page: notPositionedLabelsPage,
        }))
      }
      setNotPositionedLabelsPagesNumber(notPositionedLabelsPagesNumber + 1)
    }
  }, [notPositionedLabelsPage])

  useEffect(() => {
    if ((updatedLabel || deletedLabel) && activeProject) {
      dispatch(LibraryService.getLabels({
        projectId: activeProject.id,
        library: Libraries.NotPositioned,
        page: notPositionedLabelsPage,
      }))
      setNotPositionedLabelsPagesNumber(1)
    }
  }, [updatedLabel, deletedLabel])

  return (
    <div className="d-flex flex-row">
      {showPanel && <PortfolioPanel />}
      {displayLibrary && <LibraryPanel />}
      <MapContainer />
    </div>
  )
}
