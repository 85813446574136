import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { FiltersCategories, ObjectsLayer, ObjectsName } from 'components/Map/ObjectsLayers/types'
import { getFilterCheckStatus, getLayerVisibilityByName } from '../utils'

export default function VuLayer(): ReactElement {
  const { peLayerData, filtersList } = useSelector((state: RootState) => state.object)

  return (
    <>
      <Source type="geojson" data={peLayerData} id={ObjectsName.VU}>
        <Layer
          id={ObjectsName.VU}
          filter={['all',
            ['==', 'auto_added', true],
            ['==', 'type', 'VOIE_UNIQUE'],
          ]}
          type="line"
          paint={{
            'line-color': '#d27519',
            'line-width': 2,
            'line-opacity': 1,
          }}
          layout={{
            visibility: getLayerVisibilityByName(filtersList, FiltersCategories.ModeExploit, ObjectsName.VU,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
        <Layer
          id={`${ObjectsName.VU}-description`}
          filter={['all',
            ['==', 'auto_added', true],
            ['==', 'type', 'VOIE_UNIQUE'],
          ]}
          type="symbol"
          paint={{
            'text-color': '#d27519',
            'text-halo-width': 2,
            'text-halo-color': 'rgba(255,255,255,0.75)',
            'text-opacity': 1,
            'text-halo-blur': 1,
          }}
          layout={{
            'text-field': ['get', 'description'],
            'symbol-spacing': 200,
            'text-size': 14,
            'symbol-placement': 'line',
            'text-font': [
              'Roboto Condensed',
            ],
            visibility: getLayerVisibilityByName(filtersList, FiltersCategories.ModeExploit, ObjectsName.VU,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
      </Source>
    </>
  )
}
