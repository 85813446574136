import { InputField, ObjOfStr } from 'types'

export interface InputDataType {
  field: InputField,
  value: string | string[],
  onChange: (e: any) => void,
  extraStyle?: string,
}

export enum ErrorTypes {
    requiredField = 'requiredField'
  }

export const ValidationErrors = {
  [ErrorTypes.requiredField]: 'Error.requiredField',
}

export const validateField = (field: InputField, value: string): string | undefined => {
  if (field.required && value === '') {
    return ValidationErrors[ErrorTypes.requiredField]
  }
  return undefined
}

export const selectParser: (o: ObjOfStr) => ObjOfStr[] = rawEnum => Object.entries(rawEnum).map(([key, value]) => ({
  value: key,
  label: value,
}))
