import { Chip } from '@mui/material'
import FormInput from 'components/common/Inputs/FormInput'
import { ReactElement } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { IoMdArrowDropright } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import ImportService from '../ImportService'
import { ELIGIBILITY_GRID } from './ImportField'

export default function EligibilityGrid(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { portfolio, responseError } = useSelector((state: RootState) => state.import)
  const { activeProject } = useSelector((state: RootState) => state.creation)

  const handleChange = (value: string) => {
    if (value !== '') {
      dispatch(ImportService.updatePortfolio({
        projectId: activeProject.id,
        portfolio: { eligibility: value },
      }))
    }
  }
  return (
    <Form className="ferro-localization-form" autoComplete="off">
      <div className="ferro-localization-fields">
        <Chip label={t(`${ELIGIBILITY_GRID.label}`).toUpperCase()} />
        <div className="arrow-container">
          <div className="elipse-style" />
          <div className="line-style" />
          <IoMdArrowDropright className="arrow-style" />
        </div>
        <FormInput
          field={{
            ...ELIGIBILITY_GRID,
            values: portfolio
              ? [...portfolio.columns, ...portfolio.displayed_columns] : [],
          }}
          onChange={v => handleChange(v)}
          value=""
          extraStyle="px-2"
          responseError={responseError}
        />
      </div>
    </Form>
  )
}
