import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Project } from 'components/Dashboard/types'
import ExportService from 'components/Export/ExportService'
import {
  Cartridge, ExportParams, ObjectLegend, PortfolioLegend, VersionsFields,
} from 'components/Export/types'
import { ResponseError } from 'types'

interface ExportState {
    exportFlag: boolean
    exportFields?: ExportParams
    responseError?: ResponseError
    launchExport: boolean
    exportLoader: boolean
    svgFile?: string
    objectsLegends: ObjectLegend[]
    portfolioLegends: PortfolioLegend[]
    cartridge?: Cartridge
    cartridgeTitle?: string
    cartridgeWarnings?: string
    versions: VersionsFields[]
    exportedProject?: Project
    exportAlert: boolean
    validateCartridge: boolean
}

const initialState: ExportState = {
  exportFlag: false,
  exportLoader: false,
  launchExport: false,
  objectsLegends: [],
  portfolioLegends: [],
  versions: [],
  exportAlert: false,
  validateCartridge: false,
}

const updateLegend = (state: ExportState, action: PayloadAction<string[]>) => {
  state.objectsLegends = state.objectsLegends.filter((legend: ObjectLegend) => !action.payload.includes(legend.legend))
}

export const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {
    reset: () => initialState,
    updateExportFlag: (state, action: PayloadAction<boolean>) => {
      state.exportFlag = action.payload
    },
    updateExportFields: (state, action: PayloadAction<ExportParams>) => {
      state.exportFields = action.payload
    },
    updateExportResponseError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.responseError = action.payload
    },
    updateLaunchExport: (state, action: PayloadAction<boolean>) => {
      state.launchExport = action.payload
    },
    toggleExportLoader: (state, action: PayloadAction<boolean>) => {
      state.exportLoader = action.payload
    },
    updateSvgFile: (state, action: PayloadAction<undefined | string>) => {
      state.svgFile = action.payload
    },
    updateCartridgeTitle: (state, action: PayloadAction<string>) => {
      state.cartridgeTitle = action.payload
    },
    updateCartridgeWarnings: (state, action: PayloadAction<string>) => {
      state.cartridgeWarnings = action.payload
    },
    updateVersions: (state, action: PayloadAction<VersionsFields[]>) => {
      state.versions = action.payload
    },
    updateExportAlert: (state, action: PayloadAction<boolean>) => {
      state.exportAlert = action.payload
    },
    updateValidateCartridge: (state, action: PayloadAction<boolean>) => {
      state.validateCartridge = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(ExportService.exportProject.fulfilled, state => {
      state.responseError = undefined
    })
    builder.addCase(ExportService.exportProject.pending, state => {
      state.exportLoader = true
    })
    builder.addCase(ExportService.exportProject.rejected, (state, action) => {
      state.responseError = action.payload
      state.exportAlert = false
    })
    builder.addCase(ExportService.getExportStatus.fulfilled, (state, action) => {
      state.exportedProject = action.payload
    })
    builder.addCase(ExportService.getExportedProject.fulfilled, (state, action) => {
      state.svgFile = action.payload
    })
    builder.addCase(ExportService.getObjectsLegends.fulfilled, (state, action) => {
      state.objectsLegends = action.payload
    })
    builder.addCase(ExportService.updateObjectsLegends.fulfilled, updateLegend)
    builder.addCase(ExportService.getPortfolioLegends.fulfilled, (state, action) => {
      state.portfolioLegends = action.payload
    })
    builder.addCase(ExportService.updatePortfolioLegends.fulfilled, (state, action) => {
      state.portfolioLegends = action.payload
    })
    builder.addCase(ExportService.getCartridge.fulfilled, (state, action) => {
      state.cartridge = action.payload
    })
    builder.addCase(ExportService.updateCartridge.fulfilled, state => {
      state.validateCartridge = true
    })
  },
})

export const {
  updateExportFlag, updateExportFields, updateExportResponseError, updateLaunchExport, toggleExportLoader,
  updateSvgFile, reset, updateCartridgeTitle, updateCartridgeWarnings, updateVersions, updateExportAlert,
  updateValidateCartridge,
} = exportSlice.actions

export default exportSlice.reducer
