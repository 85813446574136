/* eslint-disable no-compare-neg-zero */
import MAIN_API from 'config/config'
import { MapRequest, ViewportProps } from 'react-map-gl'

export const DEFAULT_VIEWPORT: ViewportProps = {
  latitude: 46.88731499073388,
  longitude: 2.5682289198122756,
  zoom: 6,
  bearing: 0,
  pitch: 0,
}

export const transformRequest = (url: string | undefined, resourceType: string | undefined): MapRequest => {
  if (url === undefined) return { url: '' }
  if ((resourceType === 'Source' || resourceType === 'Tile') && url.startsWith(MAIN_API.proxy)) {
    return {
      url,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    }
  }
  return { url }
}
