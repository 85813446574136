import { ReactElement, useEffect, useState } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { polygon } from '@turf/helpers'
import center from '@turf/center'
import { RootState } from 'Store'
import { LabelsLayersIds } from '../types'

export default function LabelLayer(): ReactElement {
  const {
    labelLayerData, enterEditingMode, labelToUpdate, labelPointLayer,
  } = useSelector((state: RootState) => state.label)
  const { clickedFeatureInfo, hoveredFeatureInfo } = useSelector((state: RootState) => state.map)
  const [labelLayer, setLabelLayer] = useState<any>(null)
  const { ObjectPositionMode } = useSelector((state: RootState) => state.object)
  const { libraryLabel } = useSelector((state: RootState) => state.library)

  useEffect(() => {
    if (labelLayerData && !ObjectPositionMode && labelPointLayer === null) {
      const featuresList: Array<GeoJSON.Feature> = []
      labelLayerData.features.forEach((feature: any) => {
        const newFeature = {
          ...feature,
          geometry: {
            type: 'Point',
            coordinates:
            feature.geometry && feature.geometry.type !== null
              ? center(polygon(feature.geometry.coordinates[0])).geometry.coordinates : [],
          },
        }
        featuresList.push(newFeature)
      })

      const labelGeojson: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> = {
        type: 'FeatureCollection',
        features: featuresList,
      }
      setLabelLayer(labelGeojson)
    } else if (labelPointLayer) {
      setLabelLayer(labelPointLayer)
    } else {
      setLabelLayer(labelLayerData)
    }
  }, [labelLayerData, labelPointLayer])

  const featureInfo = clickedFeatureInfo || hoveredFeatureInfo || libraryLabel || labelToUpdate
  const getObjectId = () => {
    if (featureInfo) {
      if (featureInfo.id) return featureInfo.id
      return featureInfo.properties.object_id
    }
    return 0
  }

  return (
    <>
      <Source
        type="geojson"
        data={labelLayer}
        id={LabelsLayersIds.label}
      >
        <Layer
          id={LabelsLayersIds.label}
          type="symbol"
          minzoom={9.5}
          paint={{
            'icon-color': ['case',
              ['==', ['get', 'color'], 'null'], '#FFFFFF',
              ['get', 'color'],
            ],
            'text-color': '#202',
          }}
          layout={{
            'text-font': ['Roboto Condensed'],
            'text-size': {
              stops: [
                [9.5, 9],
                [12, 13],
              ],
            },
            'text-field': ['get', 'description'],
            'icon-image': 'etiquette',
            'icon-text-fit': 'both',
            'icon-allow-overlap': false,
            'icon-text-fit-padding': [10, 20, 10, 20],
            visibility: 'visible',
          }}
          filter={enterEditingMode && labelToUpdate.layer.id === LabelsLayersIds.label
            ? ['all', ['!=', 'object_id', labelToUpdate.properties.object_id],
              ['==', 'displayed', true]] : ['==', 'displayed', true]}
        />
        <Layer
          id={`small-${LabelsLayersIds.label}`}
          type="symbol"
          minzoom={9.5}
          paint={{
            'icon-color': '#FFFF38',
            'text-color': '#202',
          }}
          layout={{
            'text-font': ['Roboto Condensed'],
            'text-size': {
              stops: [
                [9.5, 9],
                [12, 13],
              ],
            },
            'text-field': ['get', 'description'],
            'icon-image': 'etiquette',
            'icon-text-fit': 'both',
            'icon-allow-overlap': false,
            'icon-text-fit-padding': [10, 20, 10, 20],
            visibility: 'visible',
          }}
          filter={enterEditingMode ? ['literal', false] : ['all', ['==', 'object_id', getObjectId()],
            ['==', 'displayed', true]]}
        />
      </Source>
    </>

  )
}
