import { ReactElement, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setNewRkObjectLayer, toggleObjectDeletionMode } from 'reducers/object'
import { RootState } from 'Store'
import turfExplode from '@turf/explode'
import ObjectService from '../ObjectService'
import { ToolsProps } from './types'

export default function DeleteObject({ closePopup }: ToolsProps): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { clickedFeatureInfo } = useSelector((state: RootState) => state.map)
  const [show, setShow] = useState<boolean>(false)

  const handleShow = () => {
    dispatch(toggleObjectDeletionMode(true))
    if (clickedFeatureInfo.source.includes('rk')) {
      const featuresList: Array<GeoJSON.Feature> = []
      turfExplode(clickedFeatureInfo).features.forEach((feature: any, index) => {
        const newFeature = {
          ...feature,
          geometry: {
            type: 'Point',
            coordinates:
            feature.geometry.coordinates,
          },
          properties: {
            originalIndex: index,
            ...feature.properties,
          },
        }
        featuresList.push(newFeature)
      })
      const labelGeojson: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> = {
        type: 'FeatureCollection',
        features: featuresList,
      }
      dispatch(setNewRkObjectLayer(labelGeojson))
      closePopup(undefined)
    } else {
      setShow(true)
    }
  }
  const handleClose = () => {
    setShow(false)
    closePopup(undefined)
  }

  const removeObject = async () => {
    const queryParams = {
      projectId: clickedFeatureInfo.properties.project_id,
      objectId: clickedFeatureInfo.properties.object_id,
      layer: clickedFeatureInfo.layer.id.replace('manual-', '').replace('small-', '').replace('small-manual-', '')
        .replace('CARRE_VIOLET', 'module_schematique_infra_signal')
        .replace('manual-CARRE_VIOLET', 'module_schematique_infra_signal')
        .replace('CARRE', 'module_schematique_infra_signal')
        .replace('manual-CARRE', 'module_schematique_infra_signal')
        .replace('DISQUE', 'module_schematique_infra_signal')
        .replace('manual-DISQUE', 'module_schematique_infra_signal')
        .replace('GUIDON_DARRET', 'module_schematique_infra_signal')
        .replace('manual-GUIDON_DARRET', 'module_schematique_infra_signal')
        .replace('SEMAPHORE', 'module_schematique_infra_signal')
        .replace('manual-SEMAPHORE', 'module_schematique_infra_signal')
        .replace('AVERTISSEMENT', 'module_schematique_infra_signal')
        .replace('manual-AVERTISSEMENT', 'module_schematique_infra_signal')
      ,
    }
    dispatch(ObjectService.deleteObject(queryParams))
    closePopup(undefined)
  }

  return (
    <>
      <div role="button" tabIndex={0} className="popup-items" onClick={handleShow}>
        {t('common.delete')}
      </div>
      <Modal
        show={show}
        animation
        centered
        onExited={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <h1>
              {t('Object.deleteObject')}
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-muted font-weight-light">
          {t('Object.deleteNotice')}
        </Modal.Body>
        <Modal.Footer className="mt-3 d-flex justify-content-between">
          <Button variant="outline-secondary" size="sm" onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <Button variant="outline-danger" size="sm" onClick={removeObject}>
            {t('common.delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
