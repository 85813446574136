import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { toggleFerrolocalizationNoticeDisplay } from 'reducers/import'
import ImportService from 'components/Portfolio/Import/ImportService'

export default function FerroLocalizationButton(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { ferrolocalizationNoticeDisplay, ferrolocalizationFields } = useSelector((state: RootState) => state.import)
  const { activeProject } = useSelector((state: RootState) => state.creation)

  const handleClick = () => {
    if (ferrolocalizationNoticeDisplay) {
      dispatch(toggleFerrolocalizationNoticeDisplay(false))
    } else {
      dispatch(ImportService.updatePortfolio({
        projectId: activeProject.id,
        portfolio: ferrolocalizationFields,
      }))
    }
  }

  const disabled = !ferrolocalizationNoticeDisplay && (ferrolocalizationFields === undefined
   || Object.keys(ferrolocalizationFields).filter(field => field !== 'nom_voie').length !== 3)

  return (
    <ActionButton
      text={ferrolocalizationNoticeDisplay ? t('Portfolio.Import.Buttons.start')
        : t('Portfolio.Import.Buttons.continue')}
      onClick={handleClick}
      rightIcon
      buttonStyle="start-btn"
      disabled={disabled}
    />
  )
}
