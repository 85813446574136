import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { Form } from 'react-bootstrap'
import { setUpdatedSynoptic } from 'reducers/creation'
import FormInput from 'components/common/Inputs/FormInput'
import { Project } from '../../types'
import { NAME_FIELD } from './CreationFields'

export default function NameDeclaration() : ReactElement {
  const dispatch = useDispatch()
  const { updatedSynoptic, responseError } = useSelector((state: RootState) => state.creation)

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedProject = updatedSynoptic && updatedSynoptic.time_range ? {
      time_range: updatedSynoptic.time_range,
    } : {}

    const newProject: Project = updatedSynoptic ? {
      id: updatedSynoptic.id,
      name: event.target.value,
      ...updatedProject,
    } : {
      name: event.target.value,
    }
    dispatch(setUpdatedSynoptic(newProject))
  }

  return (
    <Form className="form" autoComplete="off">
      <FormInput
        field={NAME_FIELD}
        onChange={handleNameChange}
        value={updatedSynoptic ? updatedSynoptic.name : ''}
        responseError={responseError}
      />
    </Form>
  )
}
