import {
  ReactElement, useEffect, useState,
} from 'react'
import { RootState } from 'Store'
import { useSelector } from 'react-redux'
import { InputTypes, ResponseError } from 'types'
import { Form } from 'react-bootstrap'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { IoIosClose } from 'react-icons/io'
import { InputDataType, validateField } from './utils'
import SimpleInput from './SimpleInput'
import CustomSelect from './CustomSelect'

type Props = InputDataType & {
  icon?: ReactElement
  responseError?: ResponseError,
  objectField?: string,
  index?: number | string,
  setSearchValues?: (v: string, index: number| string | undefined) => void,
  getSelectedValue?: (v: string | string[], index: number| string | undefined) => string[],
  closeButton?: boolean,
  closeButtonStyle?: string,
  onClose?: (v: any) => void,
}

const defaultProps = {
  icon: <></>,
  objectField: undefined,
  responseError: undefined,
  index: undefined,
  setSearchValues: () => null,
  getSelectedValue: () => [],
  closeButton: false,
  closeButtonStyle: '',
  onClose: () => null,
}

export default function FormInput({
  field, value, onChange, icon, extraStyle, responseError, objectField, index, setSearchValues, getSelectedValue,
  closeButton, closeButtonStyle, onClose,
}: Props): ReactElement {
  const { t } = useTranslation()
  const { emptyFields } = useSelector((state: RootState) => state.creation)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [areaErrorMessage, setAreaErrorMessage] = useState<any>(undefined)

  useEffect(() => {
    if (emptyFields && typeof value === 'string') {
      const errorText = validateField(field, value)
      if (errorText) {
        setErrorMessage(errorText)
      } else {
        setErrorMessage(undefined)
      }
    }
  }, [value, emptyFields])

  useEffect(() => {
    if (responseError) {
      if (objectField) {
        if (objectField === 'time_range') {
          if (responseError.message[objectField][field.key]) {
            setErrorMessage(responseError.message[objectField][field.key])
          } else {
            setErrorMessage(responseError.message[objectField].non_field_errors)
          }
        } else if (responseError.message[objectField]) {
          const errors: any = []
          responseError.message[objectField].map((error: any, errorIndex: number) => {
            if (error[field.key]) {
              errors.push({ index: errorIndex + 1, errorMessage: error })
            }
            setAreaErrorMessage(areaErrorMessage ? errors.concat(areaErrorMessage) : errors)
            return null
          })
        }
      } else if (responseError.message[field.key]) {
        setErrorMessage(responseError.message[field.key])
      }
    } else {
      setErrorMessage(undefined)
    }
  }, [responseError])

  const getFormContent = (inputType: string) => {
    switch (inputType) {
      case InputTypes.Select:
        return (
          <CustomSelect
            field={field}
            value={value}
            onChange={onChange}
            extraStyle={extraStyle}
            setSearchValues={setSearchValues || (() => null)}
            getSelectedValue={getSelectedValue || (() => [])}
          />
        )
      default:
        return (
          <SimpleInput
            field={field}
            value={value}
            onChange={onChange}
            icon={icon}
            extraStyle={extraStyle}
          />
        )
    }
  }

  const getErrorMessage = (fieldKey: string) => {
    if (areaErrorMessage && index) {
      const positionError = areaErrorMessage.find((err: any) => err.index === index)
      return positionError
        ? (
          <>
            <AiOutlineExclamationCircle className="mr-1" />
            {t(`${areaErrorMessage.find((err: any) => err.index === index).errorMessage[fieldKey]}`)}
          </>
        ) : ''
    }
    return (
      <>
        <AiOutlineExclamationCircle className="mr-1" />
        {t(`${errorMessage}`)}
      </>
    )
  }

  return (
    <Form.Group
      controlId="exampleForm.ControlInput1"
      className={`${errorMessage || areaErrorMessage
        ? `input-error ${extraStyle}` : `${extraStyle ? `${extraStyle}` : ''}`}`}
    >
      {!field.hideLabel && (
        <div className="d-flex justify-content-between">
          <Form.Label className="input-label">{t(`${field.label}`)}</Form.Label>
          {closeButton && (
          <div className={closeButtonStyle}>
            <IoIosClose className="action-cursor-pointer" onClick={onClose} />
          </div>
          )}
        </div>
      )}
      {getFormContent(field.inputType)}
      <Form.Control.Feedback type="invalid">
        {getErrorMessage(field.key)}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

FormInput.defaultProps = defaultProps
