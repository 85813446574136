import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import './label.scss'
import { setLabelToUpdate, toggleEnterEditingMode } from 'reducers/label'

export default function CancelLabelEditingButton(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleClick = () => {
    dispatch(toggleEnterEditingMode(false))
    dispatch(setLabelToUpdate(undefined))
  }

  return (
    <ActionButton
      text={t('Portfolio.labelGeoEditorCancel')}
      onClick={handleClick}
      buttonStyle="validate-geoeditor-btn"
    />
  )
}
