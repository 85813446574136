import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ExportDocument from 'components/Export/ExportDocument'
import Loader from 'components/Loader'
import { AppState, setError } from 'reducers/app'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import ImportInstructions from 'components/Portfolio/Import/ImportInstructions'
import { RootState } from './Store'
import CreateProject from './components/Dashboard/Creation/CreateProject'
import DashboardNavBar from './components/Dashboard/DashboardNavBar'
import TopBar from './components/TopBar'

const APP_NAME = 'ARPE'

export default function Home(): ReactElement {
  const dispatch = useDispatch()
  const { creationMode } = useSelector((state: RootState) => state.creation)
  const { exportFlag } = useSelector((state: RootState) => state.export)
  const { importFlag } = useSelector((state: RootState) => state.import)
  const { loadingLayers } = useSelector((state: RootState) => state.object)
  const {
    loading, error, severity, message,
  } = useSelector((state: RootState): AppState => state.app)

  const handleClose = () => {
    dispatch(setError(false))
  }

  return (
    <>
      <Snackbar
        open={error}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert variant="filled" severity={severity}>
          {message}
        </Alert>
      </Snackbar>
      {(loading || loadingLayers) && (
        <div
          className="d-flex align-items-center justify-content-center modal"
          style={{
            height: '100%', width: '100%', position: 'absolute', backgroundColor: 'rgba(255, 255, 255, 0.6)',
          }}
        >
          <Loader center msg="Chargement..." />
        </div>
      )}
      {!creationMode && !exportFlag && !importFlag && (
      <>
        <TopBar appName={APP_NAME} />
        <DashboardNavBar />
      </>
      )}
      {creationMode && <CreateProject />}
      {exportFlag && <ExportDocument />}
      {importFlag && <ImportInstructions />}
    </>
  )
}
