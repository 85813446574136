import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import GenericTopBar from 'components/common/TopBar/GenericTopBar'
import { updateActiveBlock } from 'reducers/synoptic'
import {
  toggleExportLoader, updateExportFlag, updateExportResponseError, updateLaunchExport,
} from 'reducers/export'

export default function CreationTopBar(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const backToPlan = () => {
    dispatch(toggleExportLoader(false))
    dispatch(updateExportFlag(false))
    dispatch(updateLaunchExport(false))
    dispatch(updateActiveBlock(t('Synoptic.navBar.createFondPlan')))
    dispatch(updateExportResponseError(undefined))
  }

  return (
    <GenericTopBar
      onStepBack={backToPlan}
      getPreviousStep={() => t('Export.backToSchema')}
      onClose={backToPlan}
      closeIcon={false}
    />
  )
}
