import { ReactElement, useEffect, useState } from 'react'
import CreationService from 'components/Dashboard/Creation/CreationService'
import { CreationLevels, Section } from 'components/Dashboard/types'
import { Col, ListGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleDisableSectionsUpdate,
  toggleModifySections, toggleValidationNotice, updateCreationMode, updateSectionsList,
} from 'reducers/creation'
import { updateActiveBlock } from 'reducers/synoptic'
import { toggleShowGeoeditor } from 'reducers/object'
import ActionButton from 'components/common/Buttons/ActionButton'
import { RootState } from 'Store'
import ObjectService from 'components/Objects/ObjectService'
import './synoptic.scss'
import { getTaskStatus } from 'components/Dashboard/Creation/utils'
import { TASKS_NAMES, TASKS_STATUS } from 'components/Dashboard/Creation/types'
import IconButton from '@mui/material/IconButton'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

export default function SectionsDetailsPanel(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeProject, disableSectionsUpdate } = useSelector((state: RootState) => state.creation)
  const [displayPanel, setDisplayPanel] = useState(true)

  useEffect(() => {
    if (activeProject && activeProject.status === CreationLevels.Temporal) {
      dispatch(updateCreationMode(true))
      dispatch(updateActiveBlock(''))
      dispatch(updateSectionsList(activeProject.sections))
    }
    if (activeProject && activeProject.status === CreationLevels.Geographic) {
      if (getTaskStatus(activeProject, TASKS_NAMES.ImportVs) === TASKS_STATUS.Pending) {
        setTimeout(() => {
          dispatch(ObjectService.getLayersStatus(activeProject.id))
        }, 5000)
      }
      dispatch(toggleDisableSectionsUpdate(getTaskStatus(activeProject, TASKS_NAMES.ImportInfra)
       !== TASKS_STATUS.Success || getTaskStatus(activeProject, TASKS_NAMES.ImportVs) !== TASKS_STATUS.Success))
    }
  }, [activeProject])

  const handleClick = () => {
    dispatch(toggleShowGeoeditor(false))
    dispatch(CreationService.updateProject({
      project: { ...activeProject, status: CreationLevels.Temporal },
      creationLevel: CreationLevels.Temporal,
    }))
    dispatch(toggleValidationNotice(false))
    dispatch(toggleModifySections(true))
    setTimeout(() => {
      dispatch(CreationService.getSingleProject(activeProject.id))
    }, 500)
  }

  function togglePanel() {
    setDisplayPanel(!displayPanel)
  }

  return (
    <div className={`${displayPanel ? 'panel' : 'panel-close'}`}>
      <div className="d-flex flex-column justify-content-between">
        <ListGroup className="sections-details">
          {activeProject && activeProject.sections.map((section: Section) => (
            <ListGroup.Item key={section.id}>
              <Row>
                <Col>{t('Inputs.sectionName')}</Col>
                <Col>{section.name}</Col>
              </Row>
              <Row>
                <Col>{t('Inputs.lineNumber')}</Col>
                <Col>{section.code_ligne}</Col>
              </Row>
              <Row>
                <Col>{t('Inputs.pkDebut')}</Col>
                <Col>{section.pk_sncf_debut}</Col>
              </Row>
              <Row>
                <Col>{t('Inputs.pkFin')}</Col>
                <Col>{section.pk_sncf_fin}</Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
        {displayPanel && (
        <div className="update-perimeter-wrapper">
          <ActionButton
            onClick={handleClick}
            leftIcon
            text={t('Synoptic.Buttons.updateGeographicPerimeter')}
            buttonStyle="update-perimeter-btn"
            disabled={disableSectionsUpdate}
          />
        </div>
        )}
        <div className="button-toggle-panel">
          <IconButton
            className="customHoverFocus"
            onClick={() => togglePanel()}
          >
            {displayPanel ? <ArrowBackIosNewIcon sx={{ color: 'white' }} />
              : <ArrowForwardIosIcon sx={{ color: 'white' }} />}
          </IconButton>
        </div>
      </div>
    </div>
  )
}
