import DeleteProjectModal from 'components/Dashboard/Deletion/DeleteProjectModal'
import { ReactElement } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AiOutlineEllipsis } from 'react-icons/ai'
import { Project } from './types'

type Props = {
  project: Project
}

export default function ToolsDropdown({ project }: Props): ReactElement {
  const { t } = useTranslation()

  const visualizeFile = () => {
    if (project.last_file_url) {
      fetch(project.last_file_url,
        {
          method: 'GET',
          headers: {
            Authorization:
              `Bearer ${localStorage.getItem('access_token')}`,
          },
        }).then(resp => resp.json())
        .then(json => {
          window.open(json, '_blank')
        })
    }
  }

  return (
    <>
      <Dropdown autoClose="outside" drop="end">
        <Dropdown.Toggle size="sm" variant="outline-light" className="card-tools-btn" id="dropdown-basic">
          <AiOutlineEllipsis />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item as="button">
            <DeleteProjectModal projectId={project.id} />
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            disabled={project.last_file_url === ''}
            onClick={visualizeFile}
          >
            {t('Dashboard.dropdown.visualize')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}
