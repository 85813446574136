import { InputField, InputTypes } from 'types'

const PORTFOLIO_LEGEND_FIELDS: InputField = {
  key: 'legendText',
  label: 'Inputs.labelText',
  placeholder: 'Inputs.legendPlacehoalder',
  required: true,
  inputType: InputTypes.SimpleField,
}

export default PORTFOLIO_LEGEND_FIELDS
