/* eslint-disable react/no-array-index-key */
import FormInput from 'components/common/Inputs/FormInput'
import { ReactElement, useState } from 'react'
import {
  Button, Card, Col, Modal, Row,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import ExportService from './ExportService'
import PORTFOLIO_LEGEND_FIELDS from './InputFields'
import { PortfolioLegend } from './types'

export default function PortfolioLegends(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { portfolioLegends } = useSelector((state: RootState) => state.export)
  const { activeProject } = useSelector((state: RootState) => state.creation)
  const [show, setShow] = useState(false)
  const [legendToUpdate, setLegendToUpdate] = useState<PortfolioLegend>()

  const handleShow = (legend: PortfolioLegend) => {
    setShow(true)
    setLegendToUpdate(legend)
  }
  const handleClose = () => setShow(false)

  const onValidate = async () => {
    dispatch(ExportService.updatePortfolioLegends({
      projectId: activeProject.id,
      legends: [...portfolioLegends.filter((legend: PortfolioLegend) => legend.index !== legendToUpdate?.index), {
        legend: legendToUpdate?.legend,
        color: legendToUpdate?.color,
        index: legendToUpdate?.index,
      }],
    }))
    handleClose()
  }

  const onLegendChange = (text: string) => setLegendToUpdate({
    legend: text,
    color: legendToUpdate ? legendToUpdate.color : '',
    index: legendToUpdate ? legendToUpdate.index : -1,
  })

  return (
    <>
      {portfolioLegends.length !== 0
        ? (
          <div className="portfolio-legend-container mr-3">
            <div className="legend-header">{t('Export.portfolioLegend')}</div>
            <div className="legend-container">
              {portfolioLegends.map((legend: PortfolioLegend, index: number) => (
                <Row key={index}>
                  <Col sm={2}>
                    <Card style={{ backgroundColor: legend.color }} />
                  </Col>
                  <Col className="porfolio-legend">
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => handleShow(legend)}
                      className="action-cursor-pointer"
                    >
                      {legend.legend}
                    </div>
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        )
        : <></>}
      <Modal
        show={show}
        animation
        centered
        onExited={handleClose}
        backdrop="static"
        keyboard={false}
        className="legend-modal"
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <h1>
              {t('Export.changeLegendTitle')}
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormInput
            field={PORTFOLIO_LEGEND_FIELDS}
            onChange={e => onLegendChange(e.target.value)}
            value={legendToUpdate?.legend || ''}
          />
        </Modal.Body>
        <Modal.Footer className="mt-3 d-flex justify-content-between">
          <Button variant="outline-secondary" size="sm" onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <Button variant="outline-primary" size="sm" onClick={onValidate}>
            {t('Export.validate')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
