import { get, patch, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Project } from 'components/Dashboard/types'
import { ThunkApi } from 'types'
import {
  Cartridge, ExportParams, ObjectLegend, PortfolioLegend,
} from './types'

type QueryParams = {
    projectId: number,
    params: ExportParams
}

const exportProject = createAsyncThunk<any, QueryParams, ThunkApi>(
  'export/exportFile',
  async (params: QueryParams, thunkApi) => {
    try {
      await post(`/module_schematique/projects/${params.projectId}/export/`,
        params.params)
      return undefined
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getExportStatus = createAsyncThunk<Project, number>(
  'creation/getExportedProject',
  async (projectId: number) => {
    const project: Project = await get(`/module_schematique/projects/${projectId}/`)
    return project
  },
)

const getExportedProject = createAsyncThunk<string, number, ThunkApi>(
  'export/getExportedProject',
  async (projectId: number, thunkApi) => {
    try {
      const response = await get(`/module_schematique/projects/${projectId}/export/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getObjectsLegends = createAsyncThunk<ObjectLegend[], number, ThunkApi>(
  'export/getObjectsLegends',
  async (projectId: number, thunkApi) => {
    try {
      const response: ObjectLegend[] = await get(`/module_schematique/projects/${projectId}/legends/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

type PatchParams = {
  projectId: number
  legends: string[]
}

const updateObjectsLegends = createAsyncThunk<string[], PatchParams, ThunkApi>(
  'export/updateObjectsLegends',
  async (params: PatchParams, thunkApi) => {
    try {
      await patch(`/module_schematique/projects/${params.projectId}/legends/`,
        params.legends)
      return params.legends
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getPortfolioLegends = createAsyncThunk<PortfolioLegend[], number, ThunkApi>(
  'export/getPortfolioLegends',
  async (projectId: number, thunkApi) => {
    try {
      const response: PortfolioLegend[] = await get(`/module_schematique/projects/${projectId}/prog_legends/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

type UpdateParams = {
  projectId: number
  legends: PortfolioLegend[]
}
const updatePortfolioLegends = createAsyncThunk<PortfolioLegend[], UpdateParams, ThunkApi>(
  'export/updatePortfolioLegends',
  async (params: UpdateParams, thunkApi) => {
    try {
      const response: PortfolioLegend[] = await patch(`/module_schematique/projects/${params.projectId}/prog_legends/`,
        params.legends)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const getCartridge = createAsyncThunk<Cartridge, number, ThunkApi>(
  'export/getCartridge',
  async (projectId: number, thunkApi) => {
    try {
      const response: Cartridge = await get(`/module_schematique/cartouche/${projectId}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const updateCartridge = createAsyncThunk<Cartridge, Cartridge, ThunkApi>(
  'export/updateCartridge',
  async (cartridge: Cartridge, thunkApi) => {
    try {
      const response: Cartridge = await patch(`/module_schematique/cartouche/${cartridge.project}`, cartridge)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        message: e.response.data,
        status: e.response.status,
      })
    }
  },
)

const ExportService = {
  exportProject,
  getExportStatus,
  getExportedProject,
  getObjectsLegends,
  updateObjectsLegends,
  getPortfolioLegends,
  updatePortfolioLegends,
  getCartridge,
  updateCartridge,
}

export default ExportService
