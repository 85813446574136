import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import PortfolioService from 'components/Portfolio/PortfolioService'
import {
  AreaFields, LabelData, LabelInfo, SearchFields, Tracks,
} from 'components/Portfolio/types'
import { ResponseError } from 'types'

interface LabelState {
    showPanel: boolean
    createdLabel?: LabelInfo
    labelText?: string
    areaFields: Array<AreaFields>
    labelLayerData: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> | null
    responseError?: ResponseError
    deletedLabel?: number
    updateMode: boolean
    updatedLabel?: LabelInfo
    labelToUpdate?: GeoJSON.Feature
    showColorPanel: boolean
    linesList: Array<string>
    tracksList: Tracks[]
    searchValue: Array<SearchFields>
    enterEditingMode: boolean
    editionParams?: LabelData
    creationMode: boolean
    labelPointLayer: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> | null
}

const initialState: LabelState = {
  showPanel: false,
  areaFields: [],
  labelLayerData: null,
  updateMode: false,
  showColorPanel: false,
  linesList: [],
  tracksList: [],
  searchValue: [],
  enterEditingMode: false,
  creationMode: false,
  labelPointLayer: null,
}

const createLabel = (state: LabelState, action: PayloadAction<LabelInfo | undefined>) => {
  state.createdLabel = action.payload
  state.showPanel = false
  state.areaFields = []
  state.responseError = undefined
  state.labelText = ''
  state.searchValue = []
  state.creationMode = true
}

const updateLabel = (state: LabelState, action: PayloadAction<LabelInfo | undefined>) => {
  state.updatedLabel = action.payload
  state.labelText = ''
  state.responseError = undefined
  if (state.updateMode) state.labelToUpdate = undefined
  state.showPanel = false
  state.updateMode = false
  state.searchValue = []
  state.enterEditingMode = false
  state.creationMode = false
}

const removeLabel = (state: LabelState, action: PayloadAction<number>) => {
  state.deletedLabel = action.payload
  state.searchValue = []
}

export const labelSlice = createSlice({
  name: 'label',
  initialState,
  reducers: {
    toggleShowPanel: (state, action: PayloadAction<boolean>) => {
      state.showPanel = action.payload
    },
    updateLabelText: (state, action: PayloadAction<string>) => {
      state.labelText = action.payload
    },
    updateAreaFields: (state, action: PayloadAction<Array<AreaFields>>) => {
      state.areaFields = action.payload
    },
    updateResponseError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.responseError = action.payload
    },
    toggleUpdateMode: (state, action: PayloadAction<boolean>) => {
      state.updateMode = action.payload
    },
    setLabelToUpdate: (state, action: PayloadAction<GeoJSON.Feature | undefined>) => {
      state.labelToUpdate = action.payload
    },
    toggleShowColorPanel: (state, action: PayloadAction<boolean>) => {
      state.showColorPanel = action.payload
    },
    updateSearchValue: (state, action: PayloadAction<Array<SearchFields>>) => {
      state.searchValue = action.payload
    },
    toggleEnterEditingMode: (state, action: PayloadAction<boolean>) => {
      state.enterEditingMode = action.payload
    },
    updateEditionParams: (state, action: PayloadAction<LabelData | undefined>) => {
      state.editionParams = action.payload
    },
    setNewLabelObjectLayer: (state, action: PayloadAction<any>) => {
      state.labelPointLayer = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(PortfolioService.createLabel.fulfilled, createLabel)
    builder.addCase(PortfolioService.createLabel.rejected, (state, action) => {
      state.responseError = action.payload
    })
    builder.addCase(PortfolioService.getLabelsLayer.fulfilled, (state, action) => {
      state.labelLayerData = action.payload
    })
    builder.addCase(PortfolioService.updateLabel.fulfilled, updateLabel)
    builder.addCase(PortfolioService.updateLabel.rejected, (state, action) => {
      state.responseError = action.payload
    })
    builder.addCase(PortfolioService.deleteLabel.fulfilled, removeLabel)
    builder.addCase(PortfolioService.deleteLabel.rejected, (state, action) => {
      state.responseError = action.payload
    })
    builder.addCase(PortfolioService.getProjectLines.fulfilled, (state, action) => {
      state.linesList = action.payload
    })
    builder.addCase(PortfolioService.getLineTracks.fulfilled, (state, action) => {
      if (state.tracksList.filter((track: Tracks) => track.line === action.payload.line).length === 0) {
        state.tracksList.push({ line: action.payload.line, value: action.payload.value })
      } else {
        state.tracksList.filter((track: Tracks) => track.line !== action.payload.line).push(
          { line: action.payload.line, value: action.payload.value },
        )
      }
    })
  },
})

export const {
  toggleShowPanel, updateLabelText, updateAreaFields, updateResponseError, toggleUpdateMode,
  setLabelToUpdate, toggleShowColorPanel, updateSearchValue, toggleEnterEditingMode,
  updateEditionParams, setNewLabelObjectLayer,
} = labelSlice.actions

export default labelSlice.reducer
