import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ImportService from 'components/Portfolio/Import/ImportService'
import { Portfolio, FerrolocalizationFields, GeneratedLabels } from 'components/Portfolio/Import/types'
import { SearchFields } from 'components/Portfolio/types'
import { ResponseError } from 'types'

interface ImportState {
    importFlag: boolean
    activeStep: number
    portfolio?: Portfolio,
    importedPortfolio?: Portfolio,
    settingsNoticeDisplay: boolean,
    displayedColumns?: string []
    labelExample?: any
    ferrolocalizationNoticeDisplay: boolean
    ferrolocalizationFields?: FerrolocalizationFields,
    showImportLoader: boolean,
    generatedLabels?: GeneratedLabels
    responseError?: ResponseError,
    searchValues: SearchFields[]
}

const initialState: ImportState = {
  importFlag: false,
  activeStep: -1,
  settingsNoticeDisplay: true,
  ferrolocalizationNoticeDisplay: true,
  showImportLoader: false,
  searchValues: [],
}

const uploadDocument = (state: ImportState, action: PayloadAction<Portfolio>) => {
  state.portfolio = action.payload
  state.displayedColumns = action.payload.displayed_columns
  state.labelExample = action.payload.example
  state.responseError = undefined
}

const updatePortfolio = (state: ImportState, action: PayloadAction<Portfolio>) => {
  if (state.activeStep === -1) {
    state.importedPortfolio = action.payload
  } else {
    state.portfolio = action.payload
  }
  state.displayedColumns = action.payload.displayed_columns
  state.labelExample = action.payload.example
  if (state.activeStep === 2) {
    state.activeStep += 1
    state.ferrolocalizationNoticeDisplay = !state.ferrolocalizationNoticeDisplay
  }
  state.responseError = undefined
}

const generateLabels = (state: ImportState, action: PayloadAction<GeneratedLabels>) => {
  state.generatedLabels = action.payload
  state.showImportLoader = !state.showImportLoader
}

export const importSlice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    reset: () => initialState,
    updateImportFlag: (state, action: PayloadAction<boolean>) => {
      state.importFlag = action.payload
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    toggleSettingsNoticeDisplay: (state, action: PayloadAction<boolean>) => {
      state.settingsNoticeDisplay = action.payload
    },
    toggleFerrolocalizationNoticeDisplay: (state, action: PayloadAction<boolean>) => {
      state.ferrolocalizationNoticeDisplay = action.payload
    },
    updateFerrolocalizationFields: (state, action: PayloadAction<FerrolocalizationFields>) => {
      state.ferrolocalizationFields = action.payload
    },
    toggleShowImportLoader: (state, action: PayloadAction<boolean>) => {
      state.showImportLoader = action.payload
    },
    updateResponceError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.responseError = action.payload
    },
    updateUploadedPorfolio: (state, action: PayloadAction<undefined>) => {
      state.portfolio = action.payload
    },
    updateSearchValues: (state, action: PayloadAction<SearchFields[]>) => {
      state.searchValues = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(ImportService.getPortfolio.fulfilled, (state, action) => {
      state.importedPortfolio = action.payload
    })
    builder.addCase(ImportService.uploadFile.fulfilled, uploadDocument)
    builder.addCase(ImportService.uploadFile.pending, state => {
      state.showImportLoader = !state.showImportLoader
    })
    builder.addCase(ImportService.uploadFile.rejected, (state, action) => {
      state.responseError = action.payload
    })
    builder.addCase(ImportService.updatePortfolio.fulfilled, updatePortfolio)
    builder.addCase(ImportService.updatePortfolio.pending, state => {
      if (state.activeStep === 0 && state.portfolio?.eligibility !== '') {
        state.showImportLoader = !state.showImportLoader
      }
    })
    builder.addCase(ImportService.updatePortfolio.rejected, (state, action) => {
      state.responseError = action.payload
    })
    builder.addCase(ImportService.generatePortfolio.fulfilled, generateLabels)
    builder.addCase(ImportService.generatePortfolio.pending, state => {
      state.showImportLoader = !state.showImportLoader
    })
  },
})

export const {
  reset, updateImportFlag, setActiveStep, toggleSettingsNoticeDisplay, toggleFerrolocalizationNoticeDisplay,
  updateFerrolocalizationFields, toggleShowImportLoader, updateResponceError, updateUploadedPorfolio,
  updateSearchValues,
} = importSlice.actions

export default importSlice.reducer
