/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import moment from 'moment'
import { ReactElement } from 'react'
import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { useTranslation } from 'react-i18next'
import { OrderBy, Project } from './types'
import 'moment/locale/fr'

import './dashboard.scss'
import DisplayTools from './DisplayTools'
import ListOrdering from './ListOrdering'
import ToolsDropdown from './ToolsDropdown'

type Props = {
  onSynopticOpen: (name: string | undefined, id: number | undefined) => void
}

export default function DashboardList({ onSynopticOpen }: Props): ReactElement {
  const { t } = useTranslation()
  const { projectsList } = useSelector((state: RootState) => state.creation)
  const { orderBy } = useSelector((state: RootState) => state.dashboard)
  const { account } = useSelector((state: RootState) => state.user)
  moment.locale('fr')

  const lastModifiedOrder = orderBy === OrderBy.AscendingLastModification
  || orderBy === OrderBy.DescendingLastModification
  const creationDateOrder = orderBy === OrderBy.AscendingCreationDate
  || orderBy === OrderBy.DescendingCreationDate

  return (
    <Table hover borderless className="table-synoptic">
      <thead>
        <tr>
          <th>{t('Dashboard.tableHeader.synoptics')}</th>
          <th className={lastModifiedOrder ? 'd-flex flex-row' : ''}>
            <ListOrdering label={t('Dashboard.lastModified')} displayIcon={lastModifiedOrder} />
          </th>
          <th className={creationDateOrder ? 'd-flex flex-row' : ''}>
            <ListOrdering label={t('Dashboard.tableHeader.creationDate')} displayIcon={creationDateOrder} />
          </th>
          <th>{t('Dashboard.createdBy')}</th>
          <div className="d-flex justify-content-end"><DisplayTools /></div>
        </tr>
      </thead>
      <tbody>
        {projectsList && projectsList.map((project: Project) => (
          <tr key={project.id}>
            <td
              className="action-cursor-pointer"
              onClick={() => onSynopticOpen(project.name, project.id)}
            >
              {project.name}
            </td>
            <td>{`${moment(project.last_modified).fromNow()}`}</td>
            <td>{`${moment(project.creation_date).format('DD/MM/YYYY')}`}</td>
            <td>{`${account.firstName} ${account.lastName}`}</td>
            <td className="d-flex justify-content-end">
              <ToolsDropdown project={project} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
