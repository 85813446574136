import { ReactElement } from 'react'

type Props = {
  msg?: string,
  center?: boolean,
}

const defaultProps: Props = {
  msg: '',
  center: false,
}

export default function Loader({
  msg, center,
}: Props): ReactElement {
  const className = center ? 'loader center' : 'loader'

  return (
    <div className={`${className} d-flex flex-column`}>
      <div className="spinner-border m-auto" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <div className="text-center mt-2">{msg}</div>
    </div>
  )
}

Loader.defaultProps = defaultProps
