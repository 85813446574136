import { InputField, InputTypes } from 'types'

export const ATTRIBUTS_FIELD: InputField = {
  key: 'attributs',
  label: '',
  placeholder: 'Inputs.attritutSelectorPlaceholder',
  required: false,
  inputType: InputTypes.Select,
  withSearch: true,
}

export const FERRO_LOCALIZATION_FIELDS: Array<InputField> = [
  {
    key: 'code_ligne',
    label: 'Inputs.lineNumber',
    hideLabel: true,
    placeholder: 'Inputs.lineAttributPlacehoalder',
    required: false,
    inputType: InputTypes.Select,
    withSearch: true,
  },
  {
    key: 'nom_voie',
    label: 'Inputs.trackName',
    hideLabel: true,
    placeholder: 'Inputs.trackAttributPlacehoalder',
    required: false,
    inputType: InputTypes.Select,
    withSearch: true,
  },
  {
    key: 'pk_debut',
    label: 'Inputs.pkDebut',
    hideLabel: true,
    placeholder: 'Inputs.pkDebutAttributPlacehoalder',
    required: false,
    inputType: InputTypes.Select,
    withSearch: true,
  },
  {
    key: 'pk_fin',
    label: 'Inputs.pkFin',
    hideLabel: true,
    placeholder: 'Inputs.pkFinAttributPlacehoalder',
    required: false,
    inputType: InputTypes.Select,
    withSearch: true,
  },
]

export const ELIGIBILITY_GRID: InputField = {
  key: 'eligibility',
  label: 'Inputs.eligibilityGrid',
  hideLabel: true,
  placeholder: 'Inputs.eligibilityGridPlacehoalder',
  required: false,
  inputType: InputTypes.Select,
  withSearch: true,
}

export const CATEGORY: InputField = {
  key: 'category',
  label: 'Inputs.category',
  hideLabel: true,
  placeholder: 'Inputs.categoryPlacehoalder',
  required: false,
  inputType: InputTypes.Select,
  withSearch: true,
}
