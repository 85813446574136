import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { CreationLevels } from '../../types'
import GeographicPerimeter from './GeographicPerimeter'
import NameDeclaration from './NameDeclaration'
import TemporalPerimeter from './TemporalPerimeter'

export default function CreationInputField(): ReactElement {
  const { creationLevel } = useSelector((state: RootState) => state.creation)

  switch (creationLevel) {
    case CreationLevels.Name:
      return <TemporalPerimeter />
    case CreationLevels.Temporal:
      return <GeographicPerimeter />
    default:
      return <NameDeclaration />
  }
}
