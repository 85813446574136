import FormInput from 'components/common/Inputs/FormInput'
import { ReactElement } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setUpdatedSynoptic } from 'reducers/creation'
import { RootState } from 'Store'
import { InputField } from 'types'
import { Project } from '../../types'
import { TIME_RANGE_FIELDS } from './CreationFields'

export default function TemporalPerimeter() : ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    updatedSynoptic, projectsList, createdProject, responseError,
  } = useSelector((state: RootState) => state.creation)

  const getInputValue = (type: string) => {
    const timeRange = updatedSynoptic && updatedSynoptic.time_range
    if (timeRange) {
      switch (type) {
        case t('Inputs.startYearLabel'):
          return timeRange.start_year !== null ? timeRange.start_year : ''
        default:
          return timeRange.end_year !== null ? timeRange.end_year : ''
      }
    }
    return ''
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, fieldLabel: string) => {
    const projectId = updatedSynoptic.id ? updatedSynoptic.id : createdProject.id
    const timeRange = updatedSynoptic.time_range ? updatedSynoptic.time_range : createdProject.time_range
    if (!Number.isNaN(+event.target.value)) {
      switch (fieldLabel) {
        case t('Inputs.startYearLabel'):
          dispatch(setUpdatedSynoptic({
            id: projectsList.filter((proj :Project) => proj.id === projectId)[0].id,
            name: projectsList.filter((proj :Project) => proj.id === projectId)[0].name,
            time_range: {
              start_year: event.target.value !== '' ? +event.target.value : null,
              end_year: timeRange.end_year,
            },
          }))
          break
        default:
          dispatch(setUpdatedSynoptic({
            id: projectsList.filter((proj :Project) => proj.id === projectId)[0].id,
            name: projectsList.filter((proj :Project) => proj.id === projectId)[0].name,
            time_range: {
              start_year: timeRange.start_year,
              end_year: event.target.value !== '' ? +event.target.value : null,
            },
          }))
          break
      }
    }
  }

  return (
    <>
      {TIME_RANGE_FIELDS.map((field: InputField) => (
        <Form key={field.key} className="form  mb-4" autoComplete="off">
          <FormInput
            field={field}
            onChange={e => handleChange(e, t(`${field.label}`))}
            value={getInputValue(t(`${field.label}`))}
            responseError={responseError}
            objectField="time_range"
          />
        </Form>
      ))}
    </>
  )
}
