import FormInput from 'components/common/Inputs/FormInput'
import { ReactElement, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { updateFilters, updateWorkPhases } from 'reducers/library'
import { RootState } from 'Store'
import { InputField } from 'types'
import { WorkPhaseFields } from './types'
import { getSelectedValue, setSearchValues } from './utils'
import PHASES_FIELD from './WorkPhasesFields'

type Props = {
    workPhasesValue: string[]
    dateValues: string[]
    index: number
}

export default function Phases({ index, workPhasesValue, dateValues }: Props): ReactElement {
  const dispatch = useDispatch()
  const { workPhases, filters } = useSelector((state: RootState) => state.library)
  const [phase, setPhase] = useState<string>('')
  const [dates, setDates] = useState<any>([])

  useEffect(() => {
    if (phase !== '' && dates.length !== 0) {
      const phases = workPhases.filter((w: WorkPhaseFields) => w.index !== index)
      dispatch(updateWorkPhases([...phases, {
        index,
        phase: [`${phase}`, typeof dates === 'string' ? [dates] : dates],
        applyedFilter: false,
      }]))
    }
  }, [phase, dates])

  useEffect(() => {
    const phases: any = []
    if (workPhases) {
      workPhases.forEach((w: WorkPhaseFields) => {
        if (w.phase.length !== 0) { phases.push(w.phase) }
      })
    }
    if (phases.length !== 0) {
      dispatch(updateFilters({ ...filters, 'Phases études/travaux': phases }))
    }
  }, [workPhases])

  const handleChange = (fieldLabel: string, value: string) => {
    if (fieldLabel === 'Portfolio.Library.Filters.workPhase') {
      setPhase(value)
    } else {
      setDates(value)
    }
  }

  const getValue = (field: string) => {
    const actualPhase = workPhases.filter((p: WorkPhaseFields) => p.index === index)
    if (field === 'Portfolio.Library.Filters.workPhase') {
      return actualPhase && actualPhase[0].phase.length !== 0
        ? actualPhase[0].phase[0] : phase
    }
    if (actualPhase && actualPhase[0].phase.length !== 0) {
      return actualPhase[0].phase[1] ? actualPhase[0].phase[1] : []
    }
    return dates
  }

  const isDisabledField = () => {
    const actualPhase: WorkPhaseFields[] = workPhases.filter((w: WorkPhaseFields) => w.index === index)
    return actualPhase && actualPhase[0].appliedFilter
  }

  return (
    <>
      <Row className="phase-container">
        {PHASES_FIELD.map((field: InputField) => (
          <Col key={field.key}>
            <FormInput
              field={field.label === 'Portfolio.Library.Filters.workPhase'
                ? { ...field, values: workPhasesValue, disabled: isDisabledField() }
                : { ...field, values: dateValues, disabled: isDisabledField() }}
              onChange={v => handleChange(field.label, v)}
              value={getValue(field.label)}
              extraStyle="mr-2 mb-3"
              index={`${field.label}-${index}`}
              setSearchValues={v => setSearchValues(v, `${field.label}-${index}`)}
              getSelectedValue={() => getSelectedValue(getValue(field.label), `${field.label}-${index}`, field.label)}
            />
          </Col>
        ))}
      </Row>
    </>
  )
}
