import { InputField, InputTypes } from 'types'

export const DESCRIPTION_FIELDS: InputField = {
  key: 'description',
  label: 'Inputs.labelText',
  placeholder: 'Inputs.labelTextPlaceholder',
  required: true,
  inputType: InputTypes.SimpleField,
  type: 'textarea',
}

export const AREA_FIELDS: Array<InputField> = [
  {
    key: 'code_ligne',
    label: 'Inputs.lineNumber',
    placeholder: 'Inputs.lineNumberLabel',
    required: true,
    inputType: InputTypes.Select,
  },
  {
    key: 'nom_voie',
    label: 'Inputs.trackName',
    placeholder: 'Inputs.trackNamePlaceholder',
    required: false,
    inputType: InputTypes.Select,
    withSearch: true,
  },
]

export const PK_FIELDS: Array<InputField> = [
  {
    key: 'pk_debut',
    label: 'Inputs.pkDebut',
    placeholder: 'Inputs.pkDebutPlaceholder',
    required: true,
    inputType: InputTypes.SimpleField,
  },
  {
    key: 'pk_fin',
    label: 'Inputs.pkFin',
    placeholder: 'Inputs.pkFinPlaceholder',
    required: true,
    inputType: InputTypes.SimpleField,
  },
]
