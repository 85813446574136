import { ReactElement } from 'react'
import { MdReorder, MdApps } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { toggleDisplayMode } from 'reducers/dashboard'
import { RootState } from 'Store'

import './dashboard.scss'

export default function DisplayTools(): ReactElement {
  const dispatch = useDispatch()
  const { cardDisplayMode } = useSelector((state: RootState) => state.dashboard)

  const onDisplayModeChange = (mode: boolean) => {
    dispatch(toggleDisplayMode(mode))
  }
  return (
    <>
      <MdReorder
        className={`display-icons action-cursor-pointer ${!cardDisplayMode ? 'display-icons-active' : ''}`}
        onClick={() => onDisplayModeChange(false)}
      />
      <MdApps
        className={`display-icons action-cursor-pointer ${cardDisplayMode ? 'display-icons-active' : ''}`}
        onClick={() => onDisplayModeChange(true)}
      />
    </>
  )
}
