import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState } from 'Store'
import ObjectService from '../ObjectService'
import { ToolsProps } from './types'

export default function FlipObject({ closePopup }: ToolsProps): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { clickedFeatureInfo } = useSelector((state: RootState) => state.map)

  const flipObject = async () => {
    const queryParams = {
      projectId: clickedFeatureInfo.properties.project_id,
      objectId: clickedFeatureInfo.properties.object_id,
      layer: clickedFeatureInfo.layer.id.replace('manual-', '')
        .replace('CARRE_VIOLET', 'module_schematique_infra_signal')
        .replace('manual-CARRE_VIOLET', 'module_schematique_infra_signal')
        .replace('CARRE', 'module_schematique_infra_signal')
        .replace('manual-CARRE', 'module_schematique_infra_signal')
        .replace('DISQUE', 'module_schematique_infra_signal')
        .replace('manual-DISQUE', 'module_schematique_infra_signal')
        .replace('GUIDON_DARRET', 'module_schematique_infra_signal')
        .replace('manual-GUIDON_DARRET', 'module_schematique_infra_signal')
        .replace('SEMAPHORE', 'module_schematique_infra_signal')
        .replace('manual-SEMAPHORE', 'module_schematique_infra_signal')
        .replace('AVERTISSEMENT', 'module_schematique_infra_signal')
        .replace('manual-AVERTISSEMENT', 'module_schematique_infra_signal'),
      params: {
        properties: {
          direction: clickedFeatureInfo.properties.direction === 'D' ? 'C' : 'D',
        },
      },
    }
    dispatch(ObjectService.patchObject(queryParams))
    closePopup(undefined)
  }

  return (
    <>
      <div role="button" tabIndex={0} className="popup-items" onClick={flipObject}>
        {t('Object.button.flip')}
      </div>
    </>
  )
}
