import { ReactElement } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { InputDataType } from './utils'

import './input.scss'

type Props = InputDataType & {
    icon?: ReactElement,
  }

const defaultProps = {
  icon: <></>,
}

export default function SimpleInput({
  field, value, onChange, icon, extraStyle,
}: Props): ReactElement {
  const { t } = useTranslation()

  return (
    <InputGroup hasValidation className="input-field">
      <Form.Control
        placeholder={t(`${field.placeholder}`)}
        onChange={onChange}
        value={value}
        pattern={field.pattern}
        maxLength={field.maxLength}
        as={field.type}
        className={extraStyle}
      />
      {icon}
    </InputGroup>
  )
}

SimpleInput.defaultProps = defaultProps
