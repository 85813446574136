import { ReactElement } from 'react'
import { Source, Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { FiltersCategories, ObjectsLayer } from 'components/Map/ObjectsLayers/types'
import { getFilterCheckStatus, getLayerVisibility } from '../utils'

export default function PosteLayer(): ReactElement {
  const { posteLayerData, filtersList } = useSelector((state: RootState) => state.object)

  return (
    <>
      <Source type="geojson" data={posteLayerData} id={ObjectsLayer.poste}>
        <Layer
          id={`${ObjectsLayer.poste}`}
          filter={['==', 'auto_added', true]}
          type="symbol"
          paint={{
            'icon-color': '#FFFFFF',
          }}
          layout={{
            'text-size': 12,
            'text-font': ['Roboto Condensed'],
            'text-allow-overlap': false,
            'text-letter-spacing': 0.05,
            'text-field': ['get', 'description'],
            'icon-image': 'simple_rectangle',
            'icon-text-fit': 'both',
            'icon-text-fit-padding': [5, 5, 5, 5],
            visibility: getLayerVisibility(filtersList, FiltersCategories.Ouvrage, ObjectsLayer.poste,
              getFilterCheckStatus(filtersList, FiltersCategories.General, ObjectsLayer.gaia)),
          }}
        />
      </Source>
    </>
  )
}
