import ActionButton from 'components/common/Buttons/ActionButton'
import { ActiveTab, CreationLevels, Project } from 'components/Dashboard/types'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { updateCreationLevel } from 'reducers/creation'
import { updateTabsList } from 'reducers/dashboard'
import { RootState } from 'Store'
import CreationService from '../CreationService'

export default function NameDeclarationButton(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { updatedSynoptic, projectsList } = useSelector((state: RootState) => state.creation)
  const { tabsList } = useSelector((state: RootState) => state.dashboard)

  const handleClick = () => {
    if (projectsList.filter((project: Project) => project.id === updatedSynoptic.id).length === 0) {
      dispatch(CreationService.createProject(updatedSynoptic))
    } else if (projectsList.filter((project: Project) => project.id === updatedSynoptic.id
      && project.name === updatedSynoptic.name).length === 0) {
      dispatch(CreationService.updateProject({ project: updatedSynoptic, creationLevel: CreationLevels.Name }))
      dispatch(updateTabsList(tabsList.map((tab: ActiveTab) => (tab.tabId === updatedSynoptic.id
        ? { tabName: updatedSynoptic.name, tabId: updatedSynoptic.id } : tab))))
    } else {
      dispatch(updateCreationLevel(CreationLevels.Name))
    }
  }

  return (
    <ActionButton
      onClick={handleClick}
      text={t('Dashboard.Buttons.temporalPerimetre')}
      rightIcon
      buttonStyle="btn-add-synoptic"
      disabled={!updatedSynoptic || updatedSynoptic.name === ''}
    />
  )
}
