/* eslint-disable no-unused-expressions */
import { ReactElement, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { updateOrderBy } from 'reducers/dashboard'
import { RootState } from 'Store'
import CreationService from './Creation/CreationService'
import { OrderBy } from './types'

type Props = {
    label: string
    displayIcon: boolean
}

export default function ListOrdering({ label, displayIcon }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { orderBy } = useSelector((state: RootState) => state.dashboard)
  const { activePage } = useSelector((state: RootState) => state.creation)

  const orderChangeAction = (order: string) => {
    dispatch(updateOrderBy(order))
    dispatch(CreationService.getProjectsList({ ordering: order, page: activePage }))
  }

  const onOrderChange = (event: MouseEvent) => {
    const header = event.currentTarget.textContent
    switch (header) {
      case t('Dashboard.tableHeader.creationDate'):
        orderBy !== OrderBy.DescendingCreationDate ? orderChangeAction(OrderBy.DescendingCreationDate)
          : orderChangeAction(OrderBy.AscendingCreationDate)
        break
      default:
        orderBy !== OrderBy.DescendingLastModification ? orderChangeAction(OrderBy.DescendingLastModification)
          : orderChangeAction(OrderBy.AscendingLastModification)
        break
    }
  }

  const getOrderingIcon = () => (orderBy === OrderBy.AscendingLastModification
     || orderBy === OrderBy.AscendingCreationDate ? <IoIosArrowRoundUp />
    : <IoIosArrowRoundDown />)

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className="action-cursor-pointer"
        onClick={(e: MouseEvent) => onOrderChange(e)}
      >
        {label}
      </div>
      <div>
        {displayIcon && getOrderingIcon()}
      </div>

    </>
  )
}
