import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { FiUpload } from 'react-icons/fi'
import Dropzone from 'react-dropzone'

import './content.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import mime from 'mime-types'
import { updateResponceError } from 'reducers/import'
import ImportService from '../ImportService'
import ImportNotice from './ImportNotice'

export default function DocumentChoice(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeProject } = useSelector((state: RootState) => state.creation)

  const handleDrop = (uploadedFile: any) => {
    if (uploadedFile.length !== 0) {
      const formData = new FormData()
      formData.append('project_id', activeProject.id)
      if (uploadedFile[0].type === '') {
        const file = new File(uploadedFile, 'file_name', { type: mime.lookup(uploadedFile[0].path) as string })
        formData.append('file', file)
      } else {
        formData.append('file', uploadedFile[0])
      }
      dispatch(ImportService.uploadFile(formData))
    } else {
      dispatch(updateResponceError({ status: '', message: t('Portfolio.Import.acceptedFormat') }))
    }
  }

  return (
    <div>
      <Dropzone
        onDrop={handleDrop}
        accept={{
          'application/vnd.ms-excel.sheet.macroenabled.12': ['.xls', '.xlsx', '.xlsm'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx', '.xlsm'],
          'application/vnd.ms-excel': ['.xls', '.xlsx', '.xlsm'],
        }}
      >
        {({
          getRootProps,
          getInputProps,
        }) => (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input
              {...getInputProps()}
            />
            <div className="import-box">
              <FiUpload className="import-icon-style" />
              <div className="import-text">
                {t('Portfolio.Import.dragAndDropNotice')}
                <strong>{t('Portfolio.Import.selectNotice')}</strong>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
      <ImportNotice />
    </div>
  )
}
