import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import ContentContainer from 'components/common/Container/ContentContainer'
import BigLoader from 'components/common/BigLoader/BigLoader'
import CreationInputField from '../InputFields/CreationInputField'
import SectionsList from './SectionsList'
import { CreationLevels } from '../../types'

import './creationContent.scss'

export default function CreationContent(): ReactElement {
  const { t } = useTranslation()
  const { creationLevel } = useSelector((state: RootState) => state.creation)

  const getTitle = () => {
    switch (creationLevel) {
      case CreationLevels.Name:
        return t('Dashboard.CreationTexts.temporalPerimetre')
      case CreationLevels.Temporal:
        return t('Dashboard.CreationTexts.geographicPerimetre')
      case CreationLevels.Geographic:
        return ''
      default:
        return t('Dashboard.CreationTexts.docName')
    }
  }

  const getNotice = () => {
    switch (creationLevel) {
      case CreationLevels.Temporal:
        return t('Dashboard.CreationTexts.geoNotice')
      case CreationLevels.Geographic:
        return (
          <div className="d-flex flex-column">
            <div className="spinner"><BigLoader /></div>
            {t('Dashboard.CreationTexts.initializationNotice')}
          </div>
        )
      default:
        return t('Dashboard.CreationTexts.notice')
    }
  }

  return (
    <>
      <ContentContainer
        fillContent={creationLevel === CreationLevels.Geographic}
        extraStyle={creationLevel === CreationLevels.Temporal ? ' geographic-content-container' : ''}
        title={getTitle()}
        notice={getNotice()}
        content={creationLevel !== CreationLevels.Geographic ? <CreationInputField /> : <></>}
      />
      {creationLevel === CreationLevels.Temporal && (
      <div className="section-container">
        <SectionsList />
      </div>
      )}
    </>
  )
}
