import { ReactElement } from 'react'
import {
  Button, Card, Col, Row,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import moment from 'moment'
import ToolsDropdown from 'components/Dashboard/ToolsDropdown'
import DisplayTools from './DisplayTools'
import { Project } from './types'
import CardOrdering from './CardOrdering'

import './dashboard.scss'

type Props = {
  onSynopticOpen: (name: string | undefined, id: number | undefined) => void
}

export default function DashboardCard({ onSynopticOpen }: Props) : ReactElement {
  const { t } = useTranslation()
  const { projectsList } = useSelector((state: RootState) => state.creation)
  const { account } = useSelector((state: RootState) => state.user)

  return (
    <>
      <div className="d-flex justify-content-between mb-4 align-items-center">
        <div className="title">{t('Dashboard.synoptics')}</div>
        <div className="d-flex align-items-center">
          <CardOrdering />
          <DisplayTools />
        </div>
      </div>
      <Row className="card-container">
        {projectsList && projectsList.map((project: Project) => (
          <Card key={project.id} className="card-synoptic text-left m-2">
            <Card.Body className="d-flex flex-column">
              <Row>
                <Col>
                  <Card.Title>{project.name}</Card.Title>
                </Col>
                <Col className="d-flex flex-row-reverse col-2">
                  <ToolsDropdown project={project} />
                </Col>
              </Row>
              <Card.Text className="last-modification">
                {`${t('Dashboard.lastModified')} 
                le ${moment(project.last_modified).format('DD/MM/YYYY')}`}
              </Card.Text>
              <div className="card-bottom">
                <Card.Text>
                  {`${t('Dashboard.createdBy')}  ${account.firstName} ${account.lastName}
                le ${moment(project.creation_date).format('DD/MM/YYYY')}`}
                </Card.Text>
                <Button
                  className="mt-auto"
                  variant="outline-dark"
                  size="sm"
                  onClick={() => onSynopticOpen(project.name, project.id)}
                >
                  {t('Dashboard.openSynoptic')}
                </Button>
              </div>
            </Card.Body>
          </Card>
        ))}
      </Row>
    </>
  )
}
