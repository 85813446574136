import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useTranslation } from 'react-i18next'

import './label.scss'
import { useDispatch } from 'react-redux'
import { toggleShowPanel } from 'reducers/label'
import { toggleDisplayLibrary } from 'reducers/library'

export default function AddLabel(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleClick = () => {
    dispatch(toggleShowPanel(true))
    dispatch(toggleDisplayLibrary(false))
  }

  return (
    <ActionButton
      text={t('Portfolio.addLabel')}
      onClick={handleClick}
      buttonStyle="add-label-btn"
    />
  )
}
