import { ReactElement } from 'react'
import { RootState } from 'Store'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toggleShowGeoeditor, setGeoeditorUrl } from 'reducers/object'
import './object.scss'

export default function SwitchToGeoeditor(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeProject, disableSectionsUpdate } = useSelector((state: RootState) => state.creation)

  const handleClick = () => {
    dispatch(toggleShowGeoeditor(true))
    dispatch(setGeoeditorUrl(`/module_schematique/projects/${activeProject.id}/stretching`))
  }

  return (
    <ActionButton
      text={t('Synoptic.Buttons.switchGeoeditor')}
      onClick={handleClick}
      buttonStyle="switch-geoeditor-btn"
      disabled={disableSectionsUpdate}
    />
  )
}
