import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { setActiveStep, toggleSettingsNoticeDisplay, updateSearchValues } from 'reducers/import'

export default function AttributsSettingsButton(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { settingsNoticeDisplay, activeStep, displayedColumns } = useSelector((state: RootState) => state.import)

  const handleClick = () => {
    if (settingsNoticeDisplay) {
      dispatch(toggleSettingsNoticeDisplay(false))
    } else {
      dispatch(setActiveStep(activeStep + 1))
      dispatch(toggleSettingsNoticeDisplay(true))
      dispatch(updateSearchValues([]))
    }
  }

  return (
    <ActionButton
      text={settingsNoticeDisplay ? t('Portfolio.Import.Buttons.start') : t('Portfolio.Import.Buttons.continue')}
      onClick={handleClick}
      rightIcon
      buttonStyle={settingsNoticeDisplay ? 'start-btn' : 'continue-btn'}
      disabled={!settingsNoticeDisplay && displayedColumns && displayedColumns.length === 0}
    />
  )
}
