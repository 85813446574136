import { ReactElement } from 'react'
import iconHome from 'assets/icons/home.svg'
import { Nav, Tab } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import {
  setUpdatedSynoptic, toggleValidationNotice, updateActiveProject, updateCreationLevel, updateCreationMode,
  updateSectionsList,
} from 'reducers/creation'
import { updateActiveTab, updateTabsList } from 'reducers/dashboard'
import SynopticNavBar from 'components/Synoptic/SynopticNavBar'
import { IoIosClose } from 'react-icons/io'
import { updateActiveBlock } from 'reducers/synoptic'
import { useTranslation } from 'react-i18next'
import { toggleShowPanel, updateResponseError } from 'reducers/label'
import {
  toggleShowObjectPanel, updateStatusLayers, toggleShowGeoeditor, toggleShowObjectCreationPanel,
} from 'reducers/object'
import { reset } from 'reducers/library'
import Dashboard from './Dashboard'
import { ActiveTab, CreationLevels, Project } from './types'

export default function DashboardNavBar(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { tabsList, activeTab } = useSelector((state: RootState) => state.dashboard)
  const { projectsList, creationMode } = useSelector((state: RootState) => state.creation)
  const { showObjectPanel, showObjectCreationPanel } = useSelector((state: RootState) => state.object)

  const onTabSelect = (tab: ActiveTab) => {
    dispatch(updateActiveTab(tab))
    dispatch(toggleValidationNotice(false))
    dispatch(toggleShowPanel(false))
    dispatch(updateResponseError(undefined))
    dispatch(toggleShowGeoeditor(false))
    dispatch(reset())
    const activeProject = projectsList.filter((project: Project) => project.id === tab.tabId)[0]

    if (activeProject) {
      dispatch(setUpdatedSynoptic(activeProject))
      dispatch(updateCreationLevel(activeProject.status))
      dispatch(updateActiveProject(activeProject))
      if (activeProject.status === CreationLevels.Geographic) {
        dispatch(updateCreationMode(false))
        dispatch(updateActiveBlock(t('Synoptic.navBar.createFondPlan')))
      } else {
        dispatch(updateCreationMode(true))
      }
      if (activeProject.status === CreationLevels.Temporal) dispatch(updateSectionsList(activeProject.sections))
    } else {
      dispatch(updateActiveProject(undefined))
      dispatch(updateCreationLevel(undefined))
      dispatch(updateStatusLayers(undefined))
    }
  }

  const onTabClose = (tabId: number | undefined) => {
    const newTabsList = tabsList.filter((tab: ActiveTab) => tab.tabId !== tabId)
    dispatch(updateTabsList(newTabsList))
    if (activeTab.tabId === tabId) {
      dispatch(updateActiveTab({ tabName: 'home', tabId: undefined }))
      dispatch(updateActiveProject(undefined))
      dispatch(updateCreationLevel(undefined))
      dispatch(reset())
    }
  }

  const onObjectHeaderClose = () => {
    dispatch(toggleShowObjectPanel(false))
    dispatch(toggleShowObjectCreationPanel(false))
  }

  return (
    <>
      <main className="mastcontainer pl-0">
        {(showObjectPanel || showObjectCreationPanel) && (
        <div className="tab-object-header">
          <div className="ml-auto">{t('Object.header')}</div>
          <IoIosClose className="action-cursor-pointer ml-auto mr-3" onClick={() => onObjectHeaderClose()} />
        </div>
        )}
        <Tab.Container id="left-tabs-example" defaultActiveKey="home">
          <Nav variant="tabs" className={`navigation-tab${creationMode ? ' mb-3' : ''}`}>
            <Nav.Item className="action-cursor-pointer">
              <Nav.Link
                eventKey="home"
                active={activeTab.tabName === 'home'}
                onClick={() => onTabSelect({ tabName: 'home', tabId: undefined })}
              >
                <img src={iconHome} width="20" alt="" />
              </Nav.Link>
            </Nav.Item>
            {tabsList && tabsList.map((tab: ActiveTab) => (
              <Nav.Item key={tab.tabId} className="d-flex justify-content-between">
                <Nav.Link
                  eventKey={tab.tabId}
                  active={activeTab.tabId === tab.tabId}
                  onClick={() => onTabSelect(tab)}
                  className="action-cursor-pointer"
                >
                  {tab.tabName}
                </Nav.Link>
                <div className={`tab-close-btn${activeTab.tabId === tab.tabId ? ' tab-active' : ''}`}>
                  <IoIosClose className="action-cursor-pointer" onClick={() => onTabClose(tab.tabId)} />
                </div>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="home" active={activeTab.tabName === 'home'}>
              <Dashboard />
            </Tab.Pane>
            {tabsList && tabsList.map((tab: ActiveTab) => (
              <Tab.Pane eventKey={tab.tabId} key={tab.tabId} active={activeTab.tabId === tab.tabId} unmountOnExit>
                <SynopticNavBar />
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </main>
    </>
  )
}
