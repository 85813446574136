import { ReactElement } from 'react'
import { Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AiOutlineInfoCircle } from 'react-icons/ai'

export default function ImportNotice(): ReactElement {
  const { t } = useTranslation()
  return (
    <Card className="import-notice-card">
      <Card.Body>
        <AiOutlineInfoCircle />
        <ul>
          <li>{t('Portfolio.Import.docNotice')}</li>
          <li>{t('Portfolio.Import.excelFilterNotice')}</li>
          <li>{t('Portfolio.Import.ferrolocationFieldsNotice')}</li>
        </ul>
      </Card.Body>
    </Card>
  )
}
