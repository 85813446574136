import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import LogoSncf from 'assets/logos/LOGO_SNCF_RESEAU.png'
import { Col, Row } from 'react-bootstrap'
import { Alert, Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateValidateCartridge } from 'reducers/export'
import CartridgeDropdown from './CartridgeDropdown'
import CartridgeTitle from './CartridgeTitle'
import CartridgeVersions from './CartridgeVersions'
import CartridgeWarnings from './CartridgeWarnings'

export default function Cartridge(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { validateCartridge } = useSelector((state: RootState) => state.export)

  return (
    <div className="cartouche-container">
      <Row className="cartouche-header">
        <Col className="d-flex justify-content-center">
          <img src={LogoSncf} alt="" />
          <div className="cartouche-header-text">
            <div>{t('Export.cartoucheTitle')}</div>
            <div>{t('Export.cartoucheTransmetter')}</div>
          </div>
        </Col>
        <CartridgeDropdown />
      </Row>
      <CartridgeTitle />
      <CartridgeWarnings />
      <CartridgeVersions />
      <Snackbar
        open={validateCartridge}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => dispatch(updateValidateCartridge(false))}
      >
        <Alert variant="filled" severity="success">
          {t('Export.validatedCartridge')}
        </Alert>
      </Snackbar>
    </div>
  )
}
