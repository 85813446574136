import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { setActiveStep } from 'reducers/import'

import './import.scss'

export default function SummaryButton(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeStep } = useSelector((state: RootState) => state.import)

  const handleClick = () => {
    dispatch(setActiveStep(activeStep + 1))
  }

  return (
    <ActionButton
      rightIcon
      text={t('Portfolio.Import.Buttons.start')}
      onClick={handleClick}
      buttonStyle="start-btn"
    />
  )
}
