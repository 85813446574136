const IMPORT_STEPS = [
  {
    label: 'Portfolio.Import.instructionsSteps.importPortfolio',
    description: 'Portfolio.Import.instructionsSteps.importPortfolioDescription',
  },
  {
    label: 'Portfolio.Import.instructionsSteps.attributsChoice',
    description: 'Portfolio.Import.instructionsSteps.attributsChoiceDescription',
  },
  {
    label: 'Portfolio.Import.instructionsSteps.confirmChoice',
    description: 'Portfolio.Import.instructionsSteps.confirmChoiceDescription',
  },
  {
    label: 'Portfolio.Import.instructionsSteps.finalStep',
    description: 'Portfolio.Import.instructionsSteps.finalStepDescription',
  },
]

export default IMPORT_STEPS
