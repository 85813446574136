import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'

export default function GenerateLabels(): ReactElement {
  const { t } = useTranslation()
  const { generatedLabels } = useSelector((state: RootState) => state.import)

  return (
    <>
      {generatedLabels && (
      <div className="imported-rows-container">
        <div className="pin-icon-container"><PinDropOutlinedIcon /></div>
        <div className="imported-rows-text">
          {generatedLabels.positioned}
          {t('Portfolio.Import.positionedLabels')}
        </div>
        <div className="not-positioned-labels">
          {generatedLabels.not_positioned}
          {t('Portfolio.Import.nonPositionedLabels')}
        </div>
      </div>
      )}
    </>
  )
}
