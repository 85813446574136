import { Chip } from '@mui/material'
import FormInput from 'components/common/Inputs/FormInput'
import { ReactElement, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { IoMdArrowDropright } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { updateFerrolocalizationFields } from 'reducers/import'
import { RootState } from 'Store'
import { InputField } from 'types'
import { FERRO_LOCALIZATION_FIELDS } from './ImportField'
import { getSelectedValue, setSearchValues } from './utils'

export default function FerrolocalizationContent(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { portfolio, ferrolocalizationFields, responseError } = useSelector((state: RootState) => state.import)
  const [lineCodeAttribut, setLineCodeAttribut] = useState('')
  const [trackAttribut, setTrackAttribut] = useState('')
  const [pkDebutAttribut, setPkDebutAttribut] = useState('')
  const [pkFinAttribut, setPkFinAttribut] = useState('')

  const getValue = (fieldLabel: string) => {
    switch (fieldLabel) {
      case t('Inputs.lineNumber'):
        return lineCodeAttribut
      case t('Inputs.trackName'):
        return trackAttribut
      case t('Inputs.pkDebut'):
        return pkDebutAttribut
      default:
        return pkFinAttribut
    }
  }

  const handleChange = (value: string, fieldLabel: string) => {
    switch (fieldLabel) {
      case t('Inputs.lineNumber'):
        setLineCodeAttribut(value)
        dispatch(updateFerrolocalizationFields({ ...ferrolocalizationFields, code_ligne: value }))
        break
      case t('Inputs.trackName'):
        setTrackAttribut(value)
        dispatch(updateFerrolocalizationFields({ ...ferrolocalizationFields, nom_voie: value }))
        break
      case t('Inputs.pkDebut'):
        setPkDebutAttribut(value)
        dispatch(updateFerrolocalizationFields({ ...ferrolocalizationFields, pk_debut: value }))
        break
      default:
        setPkFinAttribut(value)
        dispatch(updateFerrolocalizationFields({ ...ferrolocalizationFields, pk_fin: value }))
        break
    }
  }

  return (
    <Form className="ferro-localization-form" autoComplete="off">
      {FERRO_LOCALIZATION_FIELDS.map((field: InputField, index: number) => (
        <div key={field.key} className="ferro-localization-fields">
          <Chip label={t(`${field.label}`).toUpperCase()} />
          <div className="arrow-container">
            <div className="elipse-style" />
            <div className="line-style" />
            <IoMdArrowDropright className="arrow-style" />
          </div>
          <FormInput
            field={{ ...field, values: portfolio ? [...portfolio.displayed_columns, ...portfolio.columns] : [] }}
            onChange={v => handleChange(v, t(`${field.label}`))}
            value={getValue(t(`${field.label}`))}
            extraStyle="px-2"
            index={index}
            responseError={responseError}
            setSearchValues={(v: string) => setSearchValues(v, index)}
            getSelectedValue={() => getSelectedValue(getValue(t(`${field.label}`)), index)}
          />
        </div>
      ))}
    </Form>
  )
}
