import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { MapEvent, Popup } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import LabelsContextMenu from './LabelsContextMenu'
import ObjectsContextMenu from './ObjectsContextMenu'

import './contextMenu.scss'

type Props = {
  event: MapEvent
  reset: () => void
  setMapHover: (e: MapEvent | undefined) => void
}

export default function ContextMenu({
  event, reset, setMapHover,
}: Props): ReactElement | null {
  const { t } = useTranslation()
  const { activeBlock } = useSelector((state: RootState) => state.synoptic)
  const coords = event.lngLat

  return (
    <Popup
      longitude={coords[0]}
      latitude={coords[1]}
      closeButton={false}
      className="popup-wrapper"
      tipSize={0}
      anchor="top-left"
      captureScroll
      captureClick
      capturePointerMove
      onPointerMove={() => setMapHover(undefined)}
      onClose={() => reset()}
    >
      {activeBlock === t('Synoptic.navBar.addPortfolio')
        && <LabelsContextMenu closePopup={reset} />}
      {activeBlock === t('Synoptic.navBar.createFondPlan')
        && <ObjectsContextMenu closePopup={reset} />}
    </Popup>
  )
}
