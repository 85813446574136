import CustomPagination from 'components/common/Pagination/CustomPagination'
import { ReactElement, useState, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActivePage } from 'reducers/creation'
import { RootState } from 'Store'
import CreationService from './Creation/CreationService'

const MAX_PAGES = 5

export default function DashboardPagination(): ReactElement {
  const dispatch = useDispatch()
  const { pagesCount, activePage } = useSelector((state: RootState) => state.creation)
  const { orderBy } = useSelector((state: RootState) => state.dashboard)
  const [firstPage, setFirstPage] = useState(1)

  const limitIndex = MAX_PAGES < pagesCount ? MAX_PAGES : pagesCount

  const handleClick = (e: MouseEvent) => {
    const index = e.currentTarget.lastChild?.textContent

    switch (index) {
      case 'First':
        setFirstPage(1)
        dispatch(setActivePage(1))
        dispatch(CreationService.getProjectsList({ ordering: orderBy }))
        break
      case 'Last':
        setFirstPage(pagesCount - limitIndex + 1)
        dispatch(setActivePage(pagesCount))
        dispatch(CreationService.getProjectsList({ ordering: orderBy, page: pagesCount }))
        break
      case 'Previous':
        dispatch(setActivePage(activePage - 1))
        if (firstPage > 1 && activePage < firstPage + 2) {
          setFirstPage(firstPage - 1)
        }
        dispatch(CreationService.getProjectsList({ ordering: orderBy, page: activePage - 1 }))
        break
      case 'Next':
        if (activePage < pagesCount) {
          dispatch(setActivePage(activePage + 1))
        }
        if (activePage === limitIndex && activePage < pagesCount
               && limitIndex !== pagesCount) {
          setFirstPage(firstPage + 1)
        }
        dispatch(CreationService.getProjectsList({ ordering: orderBy, page: activePage + 1 }))
        break
      default:
        if (Number(index) <= pagesCount) dispatch(setActivePage(Number(index)))
        if (Number(index) === limitIndex + firstPage && Number(index) > activePage && Number(index) < pagesCount) {
          setFirstPage(firstPage + 1)
        }
        if (firstPage > 1 && Number(index) < activePage && Number(index) < firstPage + 2) {
          setFirstPage(firstPage - 1)
        }
        if (Number(index) > 3 && Number(index) > activePage) {
          setFirstPage(firstPage + 1)
        }
        dispatch(CreationService.getProjectsList({ ordering: orderBy, page: Number(index) }))
    }
  }

  return (
    <div className="d-flex justify-content-center">
      <CustomPagination firstPage={firstPage} limitIndex={limitIndex} handleClick={handleClick} />
    </div>
  )
}
