import { ReactElement, useState } from 'react'
import { Button, Modal, ModalBody } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { updateTabsList } from 'reducers/dashboard'
import { RootState } from 'Store'
import CreationService from '../Creation/CreationService'
import { ActiveTab } from '../types'

import './deletion.scss'

type Props = {
    projectId: number | undefined
}

export default function DeleteProjectModal({ projectId }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { tabsList } = useSelector((state: RootState) => state.dashboard)
  const [show, setShow] = useState<boolean>(false)

  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  const removeProject = async () => {
    if (projectId) {
      dispatch(CreationService.deleteProject(projectId))
      if (tabsList && tabsList.filter((tab: ActiveTab) => tab.tabId === projectId).length !== 0) {
        dispatch(updateTabsList(tabsList.filter((tab: ActiveTab) => tab.tabId !== projectId)))
      }
      handleClose()
    }
  }

  return (
    <>
      <div role="button" tabIndex={0} className="action-cursor-pointer" onClick={handleShow}>{t('common.delete')}</div>
      <Modal
        show={show}
        animation
        centered
        onExited={handleClose}
        backdrop="static"
        keyboard={false}
        className="delete-modal"
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <h1>
              {t('Dashboard.modal.header')}
            </h1>
          </Modal.Title>
        </Modal.Header>
        <ModalBody className="text-muted font-weight-light">
          {t('Dashboard.modal.bodyNotice')}
        </ModalBody>
        <Modal.Footer className="mt-3 d-flex justify-content-between">
          <Button variant="outline-secondary" size="sm" onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <Button variant="outline-danger" size="sm" onClick={removeProject}>
            {t('common.delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
