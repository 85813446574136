import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState } from 'Store'
import { toggleModifySections, toggleValidationNotice } from 'reducers/creation'
import CreationService from '../CreationService'

export default function GeographicPerimeterButton(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    updatedSynoptic, sectionsList,
  } = useSelector((state: RootState) => state.creation)

  const handleClick = () => {
    dispatch(CreationService.getSingleProject(updatedSynoptic.id))
    dispatch(toggleModifySections(false))
    dispatch(toggleValidationNotice(true))
  }

  return (
    <ActionButton
      onClick={handleClick}
      rightIcon
      text={t('Dashboard.Buttons.continue')}
      buttonStyle="btn-add-synoptic"
      disabled={sectionsList.length === 0}
    />
  )
}
