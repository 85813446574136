import ActionButton from 'components/common/Buttons/ActionButton'
import ObjectService from 'components/Objects/ObjectService'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { toggleObjectDeletionMode, toggleObjectPositionMode } from 'reducers/object'

import './object.scss'
import getOldLayerData from './utils'

export default function CancelObjectModification(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeProject } = useSelector((state: RootState) => state.creation)
  const {
    ObjectOrientationMode, ObjectPositionMode, ObjectDeletionMode, modifiedObjectFeature,
  } = useSelector((state: RootState) => state.object)

  const handleClick = () => {
    if (ObjectOrientationMode) {
      dispatch(ObjectService.getSignalsLayer(activeProject.id))
    }
    if (ObjectPositionMode) {
      dispatch(toggleObjectPositionMode(false))
      getOldLayerData(modifiedObjectFeature?.source, activeProject?.id, dispatch)
    }
    if (ObjectDeletionMode) {
      dispatch(toggleObjectDeletionMode(false))
      getOldLayerData(modifiedObjectFeature?.source, activeProject?.id, dispatch)
    }
  }

  return (
    <ActionButton
      text={ObjectDeletionMode ? t('Object.button.cancelDeletion') : t('Object.button.cancelModification')}
      onClick={handleClick}
      buttonStyle="validate-object-btn"
    />
  )
}
