import { CreationLevels } from 'components/Dashboard/types'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import GeographicPerimeterButton from './GeographicPerimeterButton'
import NameDeclarationButton from './NameDeclarationButton'
import TemporalPerimeterButton from './TemporalPerimeterButton'

export default function CreationButtons(): ReactElement {
  const { creationLevel } = useSelector((state: RootState) => state.creation)

  switch (creationLevel) {
    case CreationLevels.Name:
      return <TemporalPerimeterButton />
    case CreationLevels.Temporal:
      return <GeographicPerimeterButton />
    default:
      return <NameDeclarationButton />
  }
}
