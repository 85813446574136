import { InputField, InputTypes } from 'types'

export const NAME_FIELD: InputField = {
  key: 'name',
  label: 'Inputs.docNameLabel',
  placeholder: 'Inputs.docNamePlaceholder',
  required: true,
  inputType: InputTypes.SimpleField,
}

export const TIME_RANGE_FIELDS: Array<InputField> = [
  {
    key: 'start_year',
    label: 'Inputs.startYearLabel',
    placeholder: 'Inputs.startYearPlaceholder',
    required: true,
    inputType: InputTypes.SimpleField,
    pattern: '/d*',
    maxLength: 4,
  },
  {
    key: 'end_year',
    label: 'Inputs.endYearLabel',
    placeholder: 'Inputs.endYearPlaceholder',
    required: true,
    inputType: InputTypes.SimpleField,
    pattern: 'd*',
    maxLength: 4,
  },
]

export const SECTION_FIELDS: Array<InputField> = [
  {
    key: 'name',
    label: 'Inputs.sectionName',
    placeholder: 'Inputs.sectionNamePlaceholder',
    required: true,
    inputType: InputTypes.SimpleField,
  },
  {
    key: 'code_ligne',
    label: 'Inputs.lineNumber',
    placeholder: 'Inputs.lineNumberPlaceholder',
    required: true,
    inputType: InputTypes.SimpleField,
    pattern: '/d*',
    maxLength: 6,
  },
  {
    key: 'pk_sncf_debut',
    label: 'Inputs.pkDebut',
    placeholder: 'Inputs.pkPlaceholder',
    required: true,
    inputType: InputTypes.SimpleField,
  },
  {
    key: 'pk_sncf_fin',
    label: 'Inputs.pkFin',
    placeholder: 'Inputs.pkPlaceholder',
    required: true,
    inputType: InputTypes.SimpleField,
  },
]
