import FormInput from 'components/common/Inputs/FormInput'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { updateVersions } from 'reducers/export'
import { RootState } from 'Store'
import { InputField } from 'types'
import { VERSIONS_FIELDS } from '../ExportFields'
import { CartridgeVersion, VersionsFields } from '../types'

type Props = {
    existingVersion: VersionsFields
}

export default function CartridgeVersionsFields({ existingVersion }: Props): ReactElement {
  const dispatch = useDispatch()
  const { versions } = useSelector((state: RootState) => state.export)
  const user = useSelector((state: RootState) => state.user)
  const [versionIndex, setVersionIndex] = useState('')
  const [versionDate, setVersionDate] = useState('')
  const [version, setVersion] = useState('')
  const [versionComment, setVersionComment] = useState('')
  const [author, setAuthor] = useState(`${user.account.firstName} ${user.account.lastName}`)

  useEffect(() => {
    if (versionIndex !== '' || versionDate !== '' || version !== '' || versionComment !== '') {
      const newVersion = {
        index: versionIndex,
        date: versionDate,
        version,
        comment: versionComment,
        author,
      }
      const newVersionFields = versions.map((v: VersionsFields) => (v.index !== existingVersion.index
        ? v : { index: existingVersion.index, version: newVersion }))
      dispatch(updateVersions(newVersionFields))
    }
  }, [versionIndex, versionDate, version, versionComment])

  useEffect(() => {
    const actualVersion: CartridgeVersion = existingVersion.version
    if (actualVersion.index !== null) setVersionIndex((actualVersion.index.toString()))
    if (actualVersion.date !== '') setVersionDate(actualVersion.date)
    if (actualVersion.version !== '') setVersion(actualVersion.version)
    if (actualVersion.comment !== '') setVersionComment(actualVersion.comment)
  }, [existingVersion])

  const getInputValue = (filedLabel: string) => {
    const actualVersion: CartridgeVersion = existingVersion.version
    switch (filedLabel) {
      case 'Inputs.versionIndex':
        return versionIndex
      case 'Inputs.versionDate':
        return versionDate
      case 'Inputs.version':
        return version
      case 'Inputs.author':
        return actualVersion.author !== '' ? actualVersion.author : author
      default:
        return versionComment
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, fieldLabel: string) => {
    event.preventDefault()
    switch (fieldLabel) {
      case 'Inputs.versionIndex':
        if (!Number.isNaN(+event.target.value)) {
          setVersionIndex(event.target.value)
        }
        break
      case 'Inputs.versionDate':
        setVersionDate(event.target.value)
        break
      case 'Inputs.version':
        setVersion(event.target.value)
        break
      case 'Inputs.author':
        setAuthor(event.target.value)
        break
      default:
        setVersionComment(event.target.value)
        break
    }
  }
  return (
    <Form autoComplete="off" className="versions-form-container">
      {VERSIONS_FIELDS.map((field: InputField) => (
        <FormInput
          key={field.key}
          field={existingVersion.index === 1 ? field : { ...field, hideLabel: true }}
          onChange={e => handleChange(e, field.label)}
          value={getInputValue(field.label)}
          extraStyle="versions-form-input"
        />
      ))}
    </Form>
  )
}
