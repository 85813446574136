/* eslint-disable no-case-declarations */
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { updateCreationMode } from 'reducers/creation'
import { updateActiveBlock } from 'reducers/synoptic'
import { updateActiveTab, updateTabsList } from 'reducers/dashboard'
import { Alert, Snackbar } from '@mui/material'
import CreationContent from './Content/CreationContent'
import CreationTopBar from './CreationTopBar'
import {
  ActiveTab,
  CreationLevels,
} from '../types'
import CreationButtons from './Buttons/CreationButtons'
import '../dashboard.scss'

import CreationService from './CreationService'
import { TASKS_NAMES, TASKS_STATUS } from './types'
import { getTaskStatus, getValidatedSections } from './utils'

export default function CreateProject(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    creationLevel, activeProject, responseError, modifySections,
  } = useSelector((state: RootState) => state.creation)
  const { tabsList } = useSelector((state: RootState) => state.dashboard)
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    if (activeProject && activeProject.sections.length !== 0) {
      if (((getTaskStatus(activeProject, TASKS_NAMES.ImportVp) === TASKS_STATUS.Pending
        && getValidatedSections(activeProject) === activeProject.sections.length)
       || creationLevel === CreationLevels.Geographic)
      ) {
        setTimeout(() => {
          dispatch(CreationService.getSingleProject(activeProject.id))
        }, 1000)
      }
    }
  })

  useEffect(() => {
    if (activeProject && activeProject.sections.length !== 0) {
      if ((getValidatedSections(activeProject) === activeProject.sections.length
    || getTaskStatus(activeProject, TASKS_NAMES.ImportVp) === TASKS_STATUS.Failure)
    && creationLevel === CreationLevels.Temporal && !modifySections) {
        dispatch(CreationService.importSections(activeProject.id))
      }
      if (activeProject.status === CreationLevels.Geographic) {
        if (getTaskStatus(activeProject, TASKS_NAMES.ImportVp) === TASKS_STATUS.Success) {
          dispatch(updateCreationMode(false))
          dispatch(updateActiveBlock(t('Synoptic.navBar.createFondPlan')))
          if (tabsList && tabsList.filter((tab: ActiveTab) => tab.tabId === activeProject.id).length === 0) {
            const newActiveTab = { tabName: activeProject.name, tabId: activeProject.id }
            const newTabsList: Array<ActiveTab> = [newActiveTab]
            dispatch(updateTabsList(tabsList.concat(newTabsList)))
            dispatch(updateActiveTab(newActiveTab))
          }
        } else if (getTaskStatus(activeProject, TASKS_NAMES.ImportVp) === TASKS_STATUS.Failure) {
          setError(true)
          dispatch(CreationService.updateProject({
            project: { ...activeProject, status: CreationLevels.Temporal },
            creationLevel: CreationLevels.Temporal,
          }))
        }
      }
    }
  }, [activeProject])

  useEffect(() => {
    if (activeProject) {
      if (getTaskStatus(activeProject, TASKS_NAMES.ImportVp) === TASKS_STATUS.Failure) setError(true)
    }
  }, [activeProject])

  useEffect(() => {
    if (responseError && responseError.message.error) {
      setError(true)
    }
  }, [responseError])

  return (
    <div className="container-view">
      {(getTaskStatus(activeProject, TASKS_NAMES.ImportVp) === TASKS_STATUS.Failure
       || (responseError && responseError.message.error)) && (
       <Snackbar
         open={error}
         autoHideDuration={5000}
         onClose={() => setError(false)}
         anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
       >
         <Alert variant="filled" severity="warning">
           {responseError && responseError.message.error ? responseError.message.error : t('common.importError')}
         </Alert>
       </Snackbar>
      )}
      <CreationTopBar />
      <CreationContent />
      {creationLevel !== CreationLevels.Geographic && (
      <div className="d-flex justify-content-center">
        <CreationButtons />
      </div>
      )}
    </div>
  )
}
