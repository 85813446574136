import { persistCombineReducers } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage' // defaults to localStorage

import { auth } from '@osrdata/app_core'
import appReducer from './app'
import dashboardReducer from './dashboard'
import creationReducer from './creation'
import synopticReducer from './synoptic'
import exportReducer from './export'
import labelReducer from './label'
import mapReducer from './map'
import objectReducer from './object'
import geoEditorReducer from '../components/GeoEditor/reducer'
import importReducer from './import'
import libraryReducer from './library'

const userReducer = auth.reducer

const userWhiteList = [
  'account',
]

const saveUserFilter = createFilter(
  'user',
  userWhiteList,
)

const persistConfig = {
  key: 'root',
  storage,
  transforms: [saveUserFilter],
  whitelist: ['user'],
}

const rootReducer = {
  user: userReducer,
  app: appReducer,
  dashboard: dashboardReducer,
  creation: creationReducer,
  synoptic: synopticReducer,
  export: exportReducer,
  label: labelReducer,
  map: mapReducer,
  object: objectReducer,
  geoEditor: geoEditorReducer,
  import: importReducer,
  library: libraryReducer,
}

const persistedRootReducer = persistCombineReducers(persistConfig, rootReducer)

export default persistedRootReducer
