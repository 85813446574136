import { ReactElement } from 'react'
import ActionButton from 'components/common/Buttons/ActionButton'
import { useTranslation } from 'react-i18next'

import './object.scss'
import { useDispatch } from 'react-redux'
import { toggleShowObjectPanel } from 'reducers/object'

export default function AddObject(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleClick = () => {
    dispatch(toggleShowObjectPanel(true))
  }

  return (
    <ActionButton
      text={t('Synoptic.Buttons.addObject')}
      onClick={handleClick}
      buttonStyle="add-object-btn"
    />
  )
}
