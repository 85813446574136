import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosArrowRoundBack } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import {
  setLabelToUpdate,
  toggleShowPanel, toggleUpdateMode, updateAreaFields, updateLabelText, updateResponseError, updateSearchValue,
} from 'reducers/label'
import { toggleDisplayLibrary } from 'reducers/library'
import { updateClickedFeatureInfo, updateHoveredFeatureInfo } from 'reducers/map'
import { RootState } from 'Store'

import './panel.scss'

export default function PanelHeader(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { updateMode } = useSelector((state: RootState) => state.label)

  const handleClick = () => {
    dispatch(toggleShowPanel(false))
    dispatch(toggleDisplayLibrary(false))
    dispatch(updateResponseError(undefined))
    dispatch(updateSearchValue([]))
    if (updateMode) {
      dispatch(toggleUpdateMode(false))
      dispatch(updateLabelText(''))
      dispatch(updateAreaFields([]))
      dispatch(updateClickedFeatureInfo(undefined))
      dispatch(setLabelToUpdate(undefined))
      dispatch(updateHoveredFeatureInfo(undefined))
    }
  }

  const getHeader = () => (updateMode ? t('Portfolio.updateLabel') : t('Portfolio.labelCreation'))

  return (
    <div className="panel-header">
      <div className="panel-header-icon action-cursor-pointer">
        <IoIosArrowRoundBack onClick={handleClick} />
      </div>
      <div className="panel-header-text">{getHeader()}</div>
    </div>
  )
}
